import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux';
import { store } from './redux';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ScrollToTop from './context/ScrollToTop';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CookieProvider } from './context/CookieProvider';

import './i18n'
import RulesImages from './pages/GameRules/Images/RulesImages';

const queryclient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



root.render(
    <QueryClientProvider client={queryclient}>
      <BrowserRouter basename=''>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
          <Provider store={store}>
              <AuthProvider>
                  <ScrollToTop />
                  <Routes>
                    <Route path="/*" element={<App />}></Route>
                  </Routes> 
              </AuthProvider>
          </Provider>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
);
