import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from '../../lib/axios'
import { Bike, Calendar, ChevronDown, Flag } from 'lucide-react'
import Footer from '../../layouts/Footer'
import { convertToUrl } from '../../lib/utils'
import { eventsURL } from '../../lib/fetch'
import { useTranslation } from 'react-i18next'
import { getTranslationString } from '../../lib/translation'
import { Skeleton } from '../../components/ui/skeleton'
import { AlertDialog, AlertDialogContent } from '../../components/ui/alert-dialog'
import { Button } from '../../components/ui/button'
import { getCookie, setCookie } from '../../lib/cookies'
import { addDays } from 'date-fns'
import { CYCLO_PARTNER_CONFIG, getCycloPartnerConfig } from '../../config/cyclogame-partner-config'
import useAuth from '../../hooks/useAuth'

const Partner = () => {
  const {auth} = useAuth()
  const {t, i18n} = useTranslation()
  const {partnername} = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const eventsRef = useRef<HTMLDivElement>()
  const [open_join_prono, setopen_join_prono] = useState(false);

  useEffect(()=>{
    if(window.location.hash==='#events'){
      eventsRef.current.scrollIntoView()
    }

    if(location.state){
      //if(location.state.includes(partnername)){
      if(location.state.includes(partnername) && !getCookie('init-popup')){
        setCookie('init-popup', '1', addDays(new Date(), 1))
        setopen_join_prono(true)
      }
    }
  }, [])

  const changePartner = () => {
    if(auth.data.m_oPartner.m_sUrl === partnername) return;
    
    // Split current pathname by "/"
    const segments = location.pathname.split("/").filter(Boolean);
    
    // Replace the first segment with newPartner
    segments[0] = auth.data.m_oPartner.m_sUrl;
    
    // Join back into a path
    const newPath = "/" + segments.join("/");
    
    navigate(newPath);
  };

  useEffect(()=>{
    changePartner();
  }, [auth])

  const { data:partner } = useQuery<Partner | null>({
    queryKey: [`partner_${partnername}`],
    queryFn: async () => {
      try {
        const {data} = await axios.get(`partner/${partnername}`)
        if(Array.isArray(data)){throw new Error()}
        if(data){ return data }
        else { throw new Error()}
      } catch (error) {
        navigate('/')
        return null
      }
    }
  })

  const { data:events, isLoading:isLoadingEvents } = useQuery<PartnerEvent[] | null>({
    queryKey: [`events_${partnername}`],
    queryFn: async () => {
      try {
        const {data} = await axios.get(`partners/${partner.m_iPartnerID}/${eventsURL()}`)
        let filteredData = data.filter(i=>i.m_bActive===true)
        if(window.location.href.includes('#events')){
          eventsRef.current.scrollIntoView({behavior:'smooth'})
        }
        if(Array.isArray(filteredData)){ return filteredData }
        else { throw new Error()}
      } catch (error) {
        console.log(error)
        return null
      }
    },
    enabled: !!partner
  })

  const { data:pronos } = useQuery<Prono[] | null>({
    queryKey: [`pronos_${partnername}`],
    queryFn: async () => {
      try {
        if(!Array.isArray(events)){ throw new Error('Events is no array') }
        const openEventId = events.find(i=>i.m_oEvent.m_bClosed===false)?.m_iEventID || undefined
        if(!openEventId){ throw new Error('No open event found') }
        const { data } = await axios.get(`events/${openEventId}/pronos`)
        let result = data.filter(i=>i.m_bActive)
        if(!Array.isArray(result)){ throw new Error('No active pronos found') }
        result.sort(function(a, b) {
          var dateA = new Date(a.m_dtStart);
          var dateB = new Date(b.m_dtStart);
        
          if (dateA < dateB ) {
            return -1;
          }
          if (dateA > dateB ) {
            return 1;
          }
          return 0;
        });
        return result
      } catch (error) {
        console.log(error);
        setopen_join_prono(false);
        return null
      }
    },
    enabled: open_join_prono && !!events
  })

  const { data:departments } = useQuery<PartnerDepartment[] | null>({
    queryKey: [`departments_${partnername}`],
    queryFn: async () => {
      try {
        const {data} = await axios.get(`partners/${partner.m_iPartnerID}/departments`)
        if(!Array.isArray(data)){ throw new Error('no departments array')}
        return data
      } catch (error) {
        console.log(error)
        return null
      }
    },
    enabled: !!partner
  })

  function returnTranslationObject(translations:Translation[]) {
    return translations.find(i=>i.m_sLangCode===i18n.language.toUpperCase())
  }

  function findOpenOrLatestProno(pronos:Prono[]){
    if(!Array.isArray(pronos)){ return undefined }
    const now = new Date();
    const openObjects = pronos.filter(obj => !obj.m_bClosed && new Date(obj.m_dtStart) > now);

    if (openObjects.length > 0) {
        // If there are open objects after the current datetime, return the first one
        return openObjects[0];
    } else {
        // If no open objects after the current datetime, find the closest one
        const closestObject = pronos.reduce((closest, obj) => {
            const objDate = new Date(obj.m_dtStart);
            const closestDate = new Date(closest.m_dtStart);
            
            return objDate < closestDate && objDate > now ? obj : closest;
        }, pronos[0]);

        return closestObject;
    }
  }

  return (
    <>
    <AlertDialog open={open_join_prono} onOpenChange={(o)=>{setopen_join_prono(o)}}>
      <AlertDialogContent className='w-fit rounded-t-xl md:rounded-xl'>
        <p className='text-3xl font-bold text-center'>{findOpenOrLatestProno(pronos)?.m_sDescriptionNL}</p>
        <div className='min-h-[150px] rounded-lg overflow-hidden bg-neutral-100'>
          {pronos && findOpenOrLatestProno(pronos).m_sImageUrl && <img src={findOpenOrLatestProno(pronos).m_sImageUrl} alt="" className='h-full max-h-[150px] w-full object-cover object-center'/>}
        </div>
        <p className='text-xl font-semibold text-center'>{t("prono.o_popup.title")}</p>
        <p className='leading-relaxed text-center text-neutral-600'>{t("prono.o_popup.description")}</p>
        
        {(!pronos) 
        ? <Skeleton className='w-full h-[40px]'/>
        : <Link to={`${getTranslationString(i18n.language.toUpperCase() as Language, findOpenOrLatestProno(pronos).m_oEvent, "m_sUrlNL") || findOpenOrLatestProno(pronos).m_oEvent.m_sUrlNL}/${findOpenOrLatestProno(pronos).m_iPronoID}`} className='w-full'><Button variant='tifo' className='w-full'>{t("prono.o_popup.join")}</Button></Link>
        }
        
        <button onClick={()=>{setopen_join_prono(false)}} className='mx-auto text-sm text-neutral-500 w-fit hover:underline'>{t("prono.o_popup.cancel")}</button>
      </AlertDialogContent>
    </AlertDialog>

    <div className='relative flex flex-col items-center bg-tifo'>

      <div className='relative w-full pt-32 pb-10 sm:pb-32'>

        <div className='max-w-5xl h-full w-full mx-auto relative z-[2] text-white'>
          <div className='flex flex-col items-start gap-10 mx-4 lg:mx-0'>
            {/*<div className="min-w-[128px] md:min-w-[176px] bg-white/50 p-2 rounded-sm flex justify-center items-center max-w-[300px]">{partner ? <img className='object-contain w-auto h-full' src={partner.m_sLogo} alt="Logo"/> : <></>}</div>*/}
            <div>
              
              {getCycloPartnerConfig(partner?.m_iPartnerID)?.partner?.view_partner_name
              ? <h1 className='text-2xl font-bold sm:text-3xl xl:text-6xl sm:mt-4'>{partner && (partner.m_aTranslations ? (returnTranslationObject(partner.m_aTranslations)?.m_sNameTransl || partner.m_sName) : partner.m_sName)}</h1>
              : <h1 className='text-2xl font-bold sm:text-3xl xl:text-6xl sm:mt-4'>{returnTranslationObject(partner.m_aTranslations).m_sNameTransl}</h1>
              }
              
              {!partner && <Skeleton className='w-[250px] h-[46px]'/>}

              {partner && <p className='max-w-3xl mt-4 font-medium leading-loose sm:mt-16 sm:text-lg'>{partner && (partner.m_aTranslations ? (returnTranslationObject(partner.m_aTranslations)?.m_sDescTransl || partner.m_sDesc) : partner.m_sDesc)}</p>}
              {!partner &&
              <div className='flex flex-col w-full gap-1 mt-4 sm:mt-16'>
                <Skeleton className='w-[250px] sm:w-[500px] opacity-70'/>
                <Skeleton className='w-[250px] sm:w-[500px] opacity-70'/>
                <Skeleton className='w-[250px] sm:w-[500px] opacity-70'/>
                <Skeleton className='w-[250px] sm:w-[500px] opacity-70'/>
                <Skeleton className='w-[250px] sm:w-[500px] opacity-70'/>
              </div>
              }
            </div>  
          </div>        
        </div>

        <div className='absolute top-0 left-0 w-full h-full bg-red-500' id="banner">
          <div className='relative h-full'>
            <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-b from-tifo/20 to-tifo z-[1]'></div>
            {partner?.m_sBanner.includes('webm')
            ? <video className='absolute top-0 left-0 object-cover w-full h-full' autoPlay={true} muted loop><source src={partner.m_sBanner}/></video>
            : <img className='absolute top-0 left-0 object-cover w-full h-full' alt="partner banner" src={partner ? partner.m_sBanner : "https://images.pexels.com/photos/248547/pexels-photo-248547.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}/>
            }   
          </div>
        </div>

      </div>

      <div className='flex justify-center w-full py-20 bg-white' ref={eventsRef} id='events'>
        <div className='w-full max-w-5xl mx-4'>
          <h2 className='text-4xl font-bold w-fit'>{t("partner.events")}</h2>

          {events && <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4 mt-10 '>
            {events && events.map((event, index) => {
              return (
                <Link to={`${getTranslationString(i18n.language.toUpperCase() as Language, event.m_oEvent, "m_sUrlNL") || event.m_oEvent.m_sUrlNL}`} key={index}>
                <div className='flex flex-col justify-between h-full overflow-hidden duration-200 rounded-lg shadow-lg group w-fit hover:scale-105 hover:shadow-xl'>
                  <div className='max-h-[150px] sm:max-h-[250px] w-full h-full min-h-[150px] overflow-hidden'>
                    <img 
                      className={`${event.m_oEvent.m_bClosed && 'grayscale'} h-full w-full min-h-[150px] object-cover object-top group-hover:scale-[1.1] duration-500`} 
                      src={
                        event?.m_sImageUrl 
                          ? event.m_sImageUrl 
                          : (event.m_oEvent.m_sImageUrl 
                            ? event.m_oEvent.m_sImageUrl 
                            :  "https://img.aso.fr/core_app/img-cycling-prx-jpg/header-arrivee/4871/0:0,2000:690-2000-0-60/84728"
                        )
                      }
                      alt="" 
                    />
                  </div>
                  <div className='flex flex-col gap-2 p-4 bg-white'>
                    <h4 className={`${event.m_oEvent.m_bClosed ? 'text-black/70' : 'text-green-700'} text-lg font-bold`}>{getTranslationString(i18n.language.toUpperCase() as Language, event.m_oEvent, "m_sNameNL") || event.m_oEvent.m_sNameNL}</h4>
                    <div className='relative'>
                      <p className='max-h-[50px] text-sm text-neutral-400 overflow-hidden duration-200'>{getTranslationString(i18n.language.toUpperCase() as Language, event.m_oEvent, "m_sDescriptionNL") || event.m_oEvent.m_sDescriptionNL}</p>
                      <div className='absolute bottom-0 w-full h-6 bg-gradient-to-t from-white/90 to-transparent'></div>
                    </div>
                    <div className='flex items-center justify-between'>
                      <p className={`${!event.m_oEvent.m_bClosed && 'text-green-700 underline font-medium'} text-sm flex items-center gap-2`}>
                      {event.m_oEvent.m_bClosed ? <Flag className='w-4' strokeWidth={1.5}/> : <Bike className='w-4' strokeWidth={1.5}/> }
                      {event.m_oEvent.m_bClosed ? t("partner.expired") : t("partner.join")}</p>
                    </div>        
                  </div>
                </div>
                </Link>
              )
            })}
          </div>}
          {(!events && !isLoadingEvents) && <p className='my-4 italic text-neutral-500'>{t("partner.no_events")}</p>}
        </div>
      </div>
      
      {getCycloPartnerConfig(auth.data.m_oPartner.m_iPartnerID).partner.view_departments && <>
        {(departments && departments.length > 1) && 
        <>
        <div className='flex justify-center w-full py-32 text-white'>
          <div className='flex flex-col w-full max-w-5xl gap-10 mx-4'>
            <h2 className='text-4xl font-bold w-fit'>{t("partner.departments")}</h2>
            <div className='grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-4'>
                {departments?.map((department,i)=>{
                  return (
                    <Link to={`team/${department.m_sUrl}`}  key={i}>
                    <div className='flex flex-col gap-4 duration-200 hover:scale-105 group'>
                      <div className='bg-white/80 w-full h-[200px] rounded-sm overflow-hidden'>
                        <img className='object-cover w-full h-full' src={department ? department.m_sImageUrl : "https://images.pexels.com/photos/248547/pexels-photo-248547.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"} alt="" />
                      </div>
                      <div>
                        <p className='text-lg font-medium'>{department.m_aTranslations ? (returnTranslationObject(department.m_aTranslations)?.m_sNameTransl || department.m_sName) : department.m_sName}</p>
                        <p className='text-sm opacity-50 group-hover:underline'>{t("partner.view_department")}</p>
                      </div>
                    </div>
                    </Link>
                  )
                })}
            </div>
          </div>
        </div>
        <div className='w-full py-16 bg-white'></div>
        </>
        }
      </>}
      
    </div>
    <Footer />
    </>
  )
}

export default Partner