export const CYCLO_PRONOTYPE_CONFIG:CYCLO_PRONOTYPE_CONFIG_PROPS = {
    default: {
        name: "default",
        tiebreaker: {
            min: 30,
            max: 60
        }
    },
    "1": {
        name: "bergrit",
        tiebreaker: {
            min: 30,
            max: 44
        }
    },
    "2": {
        name: "heuvelrit",
        tiebreaker: {
            min: 30,
            max: 49
        }
    },
    "3": {
        name: "kasseirit",
        tiebreaker: {
            min: 35,
            max: 49
        }
    },
    "4": {
        name: "gravelrit",
        tiebreaker: {
            min: 35,
            max: 49
        }
    },
    "5": {
        name: "vlakke rit",
        tiebreaker: {
            min: 37,
            max: 50
        }
    },
    "6": {
        name: "tijdrit",
        tiebreaker: {
            min: 35,
            max: 57
        }
    },
    "7": {
        name: "monument",
        tiebreaker: {
            min: 37,
            max: 49
        }
    },
    "8": {
        name: "ploegentijdrit",
        tiebreaker: {
            min: 43,
            max: 60
        }
    }
}

export const getCycloPronotypeConfig = (pronoTypeId:string | number) => {
    const config = CYCLO_PRONOTYPE_CONFIG[pronoTypeId.toString()]
    if(config) { return config }
    return CYCLO_PRONOTYPE_CONFIG.default
}

interface CYCLO_PRONOTYPE_CONFIG_PROPS {
    [pronoTypeId:string]: {
        name: string;
        tiebreaker: {
            min: number;
            max: number;
        }
    }
}