import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../../layouts/Footer'

const GameRulesDemo = () => {
    const { i18n } = useTranslation()

    return (
    <div className='h-full overflow-auto'>
        <div className='flex flex-col justify-between h-full'>
            <div className='w-full h-[88px] min-h-[88px] bg-tifo'></div>

            {i18n.language === 'nl' &&
            <div className='relative flex justify-center h-full min-h-screen'>
                <div className='absolute top-0 z-10 w-full h-10 bg-white'></div>
                <iframe 
                    className='h-full'
                    src="https://supreme-nymphea-fdf.notion.site/ebd/1b38d81f96a7801a9a80f0f370a93ada" width="100%" frameBorder="0" allowFullScreen
                />
            </div>
            }

            {i18n.language === 'fr' &&
            <div className='flex justify-center h-full min-h-screen'>
                <div className='absolute top-0 z-10 w-full h-10 bg-white'></div>
                <iframe 
                    className='h-full'
                    src="https://supreme-nymphea-fdf.notion.site/ebd/1b38d81f96a780f3abfade131a3ddf1d" width="100%" frameBorder="0" allowFullScreen
                />
            </div>
            }

            {i18n.language === 'en' &&
            <div className='flex justify-center h-full min-h-screen'>
                <div className='absolute top-0 z-10 w-full h-10 bg-white'></div>
                <iframe 
                    className='h-full'
                    src="https://supreme-nymphea-fdf.notion.site/ebd/1b38d81f96a7801fb81dc5e9aac09d92" width="100%" frameBorder="0" allowFullScreen
                />
            </div>
            }

            {i18n.language === 'de' &&
            <div className='flex justify-center h-full min-h-screen'>
                <div className='absolute top-0 z-10 w-full h-10 bg-white'></div>
                <iframe 
                    className='h-full'
                    src="https://supreme-nymphea-fdf.notion.site/ebd/1b38d81f96a7808b8acce5db13d2696d" width="100%" frameBorder="0" allowFullScreen
                />
            </div>
            }          

            <Footer />
        </div>
    </div>
    )
}

export default GameRulesDemo