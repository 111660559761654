import React, { HTMLAttributes } from 'react'
import logo from '../assets/logo/logo_white.svg'
import { Link } from 'react-router-dom'
import { Facebook, Globe, Instagram, Linkedin, Youtube } from 'lucide-react'
import { format } from 'date-fns'
import useAuth from '../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select'
import { cn } from '../lib/utils'
import PartnerLanguagePicker from '../components/language/partner-language-picker'

const Footer = ({className}:HTMLAttributes<HTMLDivElement>) => {
  const {t, i18n} = useTranslation()
  const {auth} = useAuth()
  return (
    <div className={cn('bg-tifo text-white py-16 flex justify-center', className)}>
        <div className='flex flex-wrap justify-between w-full max-w-5xl gap-4 mx-4'>
          {/* Left */}
          <div className='flex flex-col gap-2'>
            <img className='h-8' src={logo} alt="logo tifogame"/>
            <p className='text-xs opacity-50'>Copyright {format(new Date, "yyyy")}</p>
          </div>

          {/* Right */}
          <div className='flex flex-col w-full gap-4 sm:w-3/5'>
            {/* Pages */}
            <div className='grid grid-cols-1 gap-2 text-sm sm:grid-cols-3'>
              <Link to="/" className='grid grid-cols-[2px,1fr] gap-1 opacity-75 hover:opacity-100'><div className='w-[2px] h-full rounded-full bg-tifo-light'></div><p>{t("footer.home")}</p></Link>
              {auth.status==='authenticated' && <Link to="/klassementen" className='grid grid-cols-[2px,1fr] gap-1 opacity-75 hover:opacity-100'><div className='w-[2px] h-full rounded-full bg-tifo-light'></div><p>{t("footer.ranking")}</p></Link>}
              <Link to="/wedstrijdreglement" className='grid grid-cols-[2px,1fr] gap-1 opacity-75 hover:opacity-100'><div className='w-[2px] h-full rounded-full bg-tifo-light'></div><p>{t("footer.rules")}</p></Link>
              <Link to="/cookies" className='grid grid-cols-[2px,1fr] gap-1 opacity-75 hover:opacity-100'><div className='w-[2px] h-full rounded-full bg-tifo-light'></div><p>Cookies</p></Link>

            </div>

            <div className='w-full h-px rounded-full bg-white/40'></div>
            {/* Socials */}
            <div className='flex justify-between gap-4'>
              
              <PartnerLanguagePicker
                languages={auth?.data?.m_oPartner?.m_aLanguages?.map(i=>i.m_sLangCode?.toLowerCase())}
                selectTriggerClassName='bg-transparent rounded-md w-fit border-tifo-light'
              />
              
              <div className='flex items-center gap-4'>
                <Link to="https://www.linkedin.com/company/tifogroup/"><Linkedin className='w-5 text-tifo-light'/></Link>
                <Link to="https://www.youtube.com/channel/UC5-n6YS8G6qQ5WMQB7VUOVg"><Youtube className='w-5 text-tifo-light'/></Link>
                <Link to="https://www.facebook.com/tifogame/"><Facebook className='w-5 text-tifo-light'/></Link>
                <Link to="https://www.instagram.com/tifogame/"><Instagram className='w-5 text-tifo-light'/></Link>
                <Link to="https://www.tifogame.be/nl"><Globe className='w-5 text-tifo-light'/></Link>
              </div>
            </div>
          </div>
          
        </div>
    </div>
  )
}

export default Footer