import { AnimatePresence, motion as m } from 'framer-motion'
import bg from '../../assets/images/bg/002.jpg'
import { Aperture, Clock, Loader2, MousePointerClick, PartyPopper, Save, Settings } from 'lucide-react'
import React, { useState, useEffect } from 'react'
import { Button } from '../../components/ui/button'
import { Label } from '../../components/ui/label'
import { Input } from '../../components/ui/input'
import { Checkbox } from '../../components/ui/checkbox'
import useAuth from '../../hooks/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import profilePicture from '../../assets/images/riders/anonymous_profile.png'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from '../../lib/axios'
import { format, isValid } from 'date-fns'
import { convertToUrl, delay, isInterface } from '../../lib/utils'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { eventsURL } from '../../lib/fetch'
import { getTranslationString } from '../../lib/translation'
import Footer from '../../layouts/Footer'
import { AxiosError } from 'axios'

type Pages = 'profile' | 'settings'

const Profile = () => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const {auth, setAuth} = useAuth()
    
    const [page, setpage] = useState<Pages>('profile');

    useEffect(() => {
        if(auth.status==='unauthenticated'){ navigate('/'); return; }
    }, [auth])

    const tryLoginOnRender = async () => {
        try {
          const {data:me} = await axios.get(`me`)
          if(me){setAuth({data:me, status:'authenticated'})}
        } catch (err) {
          setAuth({data:null, status:'unauthenticated'})
          //console.log(err)
        }
    }

    const {data:userPronoResults} = useQuery<UserPronoResult[][] | null | 'empty'>({
        queryKey: [`user_prono_results`],
        queryFn: async () => {
            try {
                const {data} = await axios.get(`user-prono-results?userid=${auth.data.m_iUserID}`)
                
                let filteredData = data.filter(i=>i.m_oProno.m_bActive===true)
                filteredData.sort((a, b)=>{
                    var dateA = new Date(a.m_oProno.m_dtStart);
                    var dateB = new Date(b.m_oProno.m_dtStart);

                    if (dateA > dateB ) {
                        return -1;
                    }
                    if (dateA < dateB ) {
                        return 1;
                    }
                    return 0;
                })

                const groupedObjects = filteredData.reduce((result, obj) => {
                    // Check if there is already an array for the current groupID
                    const groupArray = result.find(group => group[0]?.m_iPronoID === obj.m_iPronoID);
                  
                    // If a group array exists, add the current object to it
                    if (groupArray) {
                      groupArray.push(obj);
                    } else {
                      // If a group array doesn't exist, create a new array with the current object
                      result.push([obj]);
                    }
                  
                    return result;
                }, []);

                return groupedObjects
            } catch (error) {
                if(error.response.status===404){return 'empty'}
                return null
            }
        },
        enabled: !!auth.data
    })

    const { data:events } = useQuery<PartnerEvent[] | null>({
        queryKey: [`events`],
        queryFn: async () => {
          try {
            const {data} = await axios.get(`partners/${auth.data.m_oPartner.m_iPartnerID}/${eventsURL()}`)
            if(!Array.isArray(data)){throw new Error('No array')}
            let result = data.filter(i=>i.m_bActive===true).reverse()
            return result
          } catch (error) {
            return null
          }
        }
    })

    const ProfileScreen = () => {
        return (
            <div className='flex flex-col gap-4'>
                <div className='flex justify-between'>
                    <p className="text-xl font-medium">{t("profile.title")}</p>
                    <Button onClick={()=>{setpage('settings')}} variant='tifo' className='gap-2'><Settings className='w-4'/><p className='hidden sm:block'>{t("profile.btn_edit")}</p></Button>
                </div>
                
                <div className='flex items-center gap-8'>
                    <div className='items-center justify-center hidden overflow-hidden border-4 border-white rounded-full select-none sm:flex sm:w-28 sm:h-28 bg-white/20'>
                        <p className='text-6xl font-bold'>{auth.data.m_sFirstName.charAt(0)}</p>
                    </div>
                
                    <div className='flex flex-col gap-2'>
                        <p className='text-xl font-medium leading-loose'>{auth.data.m_sFirstName} {auth.data.m_sLastName}</p>

                        <div className='flex flex-wrap gap-4 sm:gap-8'>
                            <div className='flex flex-col gap-1'>
                                <p className='text-sm opacity-70'>{t("profile.email")}</p>
                                <p className='font-medium'>{auth.data.m_sEmail}</p>
                            </div>
                            {auth.data.m_oDepartment && auth.data.m_oDepartment.m_sName && 
                            <div className='flex flex-col gap-1'>
                                <p className='text-sm opacity-70'>{t("profile.department")}</p>
                                <p className='font-medium'>{auth.data.m_oDepartment.m_sName}</p>
                            </div> 
                            }
                        </div>
                    </div>
                </div>

                <div className='flex flex-wrap items-center gap-4'>
                    <div className='flex items-center w-full gap-4 p-2 rounded-lg bg-white/10 sm:w-fit sm:p-4'>
                        <div className='flex items-center justify-center w-10 h-10 rounded-full bg-white/10'>
                            <MousePointerClick className='w-5'/>
                        </div>
                        <div>
                            <p className='text-xl font-semibold'>{isInterface(userPronoResults, {} as UserPronoResult[][]) ? userPronoResults.length : 0}</p>
                            <p className='text-sm opacity-70'>{t("profile.predicted")}</p>
                        </div>
                    </div>

                    {/* <div className='flex items-center w-full gap-4 p-2 rounded-lg bg-white/10 sm:w-fit sm:p-4'>
                        <div className='flex items-center justify-center w-10 h-10 rounded-full bg-white/10'>
                            <PartyPopper className='w-5'/>
                        </div>
                        <div>
                            <p className='text-xl font-semibold'>0</p>
                            <p className='text-sm opacity-70'>Juist voorspeld</p>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }

    const SettingsScreen = () => {
        const [nickname, setnickname] = useState(auth.data.m_sNickname);
        const [firstname, setfirstname] = useState(auth.data.m_sFirstName);
        const [lastname, setlastname] = useState(auth.data.m_sLastName);
        const [email, setemail] = useState(auth.data.m_sEmail);

        const {mutate:updateUser, isPending:isUpdating} = useMutation({
            mutationFn: async () => {
                try {
                    await axios.patch(`users`, {
                        userid: auth.data.m_iUserID,
                        email: email,
                        username: nickname,
                        firstname: firstname,
                        lastname: lastname,
                    })
                    toast.success(t("profile.toast_success_edit"), {id:'saved_profile', duration:1500})
                    await tryLoginOnRender()
                    setpage('profile')
                } catch (error) {
                    if(error instanceof AxiosError){ 
                        if(error.response.status === 400){
                            if(error.response.data.toLowerCase().includes('user id not supplied')){
                                toast.error(`${t("error.400_users_patch")}`, {duration:5000}); return;
                            }
                            if(error.response.data.toLowerCase().includes('email is empty')){
                                toast.error(`${t("error.400_users_patch-2")}`, {duration:5000}); return;
                            }
                            if(error.response.data.toLowerCase().includes('user could not be saved')){
                                toast.error(`${t("error.400_users_patch-3")}`, {duration:5000}); return;
                            }
                        }
                        if(error.response.status === 401){
                            if(error.response.data.toLowerCase().includes('token')){
                                toast.error(`${t("error.401_users_patch")}`, {duration:5000}); return;
                            }
                        }
                        if(error.response.status === 403){
                            if(error.response.data.toLowerCase().includes('another user')){
                                toast.error(`${t("error.403_users_patch")}`, {duration:5000}); return;
                            }
                        }
                        if(error.response.status === 404){
                            if(error.response.data.toLowerCase().includes('user not found')){
                                toast.error(`${t("error.404_users_patch")}`, {duration:5000}); return;
                            }
                        }
                    }
                    toast.error(t("profile.toast_error_edit"))
                    console.log(error);
                }
            }
        })

        function hasNewValue() {
            if(nickname!==auth.data.m_sNickname){return true}
            if(firstname!==auth.data.m_sFirstName){return true}
            if(lastname!==auth.data.m_sLastName){return true}
            if(email!==auth.data.m_sEmail){return true}
            return false
        }

        return (
            <>
            <div className='flex flex-wrap-reverse justify-between gap-4 mb-4'>
                <p className="text-xl font-medium">{t("profile.settings")}</p>

                <div className='flex items-center'>
                    <Button onClick={()=>{setpage('profile')}} variant='ghost' className='hover:bg-transparent hover:text-white hover:underline'>{t("profile.back")}</Button>
                    <Button disabled={!hasNewValue() || isUpdating} onClick={()=>{updateUser()}} variant='tifo' className='gap-2'>{isUpdating ? <><Loader2 className='w-4 animate-spin'/><p className='hidden sm:block'>{t("profile.saving")}</p></> : <><Save className='w-4'/><p className='hidden sm:block'>{t("profile.save")}</p></>}</Button>
                </div>
                
            </div>

            <div className='flex flex-col max-w-xl gap-4'>
                <div className='hidden'>
                    <div className='w-20 h-20 mb-2 overflow-hidden bg-white rounded-full'>
                        <img className='object-cover w-full h-full' src="https://img.freepik.com/vrije-photo/blij-dat-donkerharige-jonge-vrouw-zegt-dat-het-goed-klinkt-bevestigt-iets-alles-onder-controle-en-gaat-geweldig-keurt-promo-goed-heeft-een-blije-uitdrukking-is-het-eens-met-de-persoon-draagt-een-geel-sweatshirt_273609-42865.jpg" alt="" />
                    </div>
                    <Input variant='transparent' type="file" />
                </div>
                <div>
                    <Label htmlFor='email'>{t("profile.email")}</Label>
                    <Input disabled value={email} variant='transparent' id='email'/>
                </div>
                <div>
                    <Label htmlFor='username'>{t("profile.username")}</Label>
                    <Input value={nickname} onChange={({currentTarget})=>{setnickname(currentTarget.value)}} variant='transparent' id='username'/>
                </div>
                <div>
                    <Label htmlFor='firstname'>{t("profile.firstname")}</Label>
                    <Input value={firstname} onChange={({currentTarget})=>{setfirstname(currentTarget.value)}} variant='transparent' id='firstname'/>
                </div>
                <div>
                    <Label htmlFor='lastname'>{t("profile.lastname")}</Label>
                    <Input value={lastname} onChange={({currentTarget})=>{setlastname(currentTarget.value)}}  variant='transparent' id='lastname'/>
                </div>
                
            </div>
            </>
        )
    }

    const latestOpenEvent = events ? events.find(i=>i.m_oEvent.m_bClosed === false) : undefined

  return (
    <>
    
    <div className='bg-gray-800 pt-[88px] h-full'>
        <div className='relative z-10 flex flex-col max-w-5xl gap-8 px-4 py-4 mx-auto lg:px-0 sm:py-8'>
            <div className='flex flex-col gap-6 p-4 overflow-hidden text-white rounded-md bg-black/20 backdrop-blur-md sm:p-8'>
                <Toaster />
                <AnimatePresence mode='wait'>
                    {page === 'profile' && 
                    <m.div key='0' className='w-full' initial={{opacity:0, x:-100}} animate={{opacity:1, x:0}} exit={{opacity:0, x:100}}>
                        <ProfileScreen />
                    </m.div>
                    }

                    {page === 'settings' && 
                    <m.div key='1' className='w-full' initial={{opacity:0, x:-100}} animate={{opacity:1, x:0}} exit={{opacity:0, x:100}}>
                        <SettingsScreen />
                    </m.div>
                    }
                </AnimatePresence>
            </div>

            <div className='flex flex-col gap-6 p-4 text-white rounded-md bg-black/20 backdrop-blur-md sm:p-8'>
                <div className='flex flex-wrap justify-between gap-4'>
                    <p className="text-xl font-medium">{t("profile.predictions")}</p>
                    {latestOpenEvent && <Link to={`/event/${latestOpenEvent.m_oEvent.m_sUrlNL}`} className='w-fit'><Button variant='tifo'>{t("profile.join_event")} {getTranslationString(i18n.language.toUpperCase() as Language, latestOpenEvent.m_oEvent, 'm_sNameNL')}</Button></Link>}
                </div>
                

                <div className='flex-wrap grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] sm:grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
                    {isInterface(userPronoResults, {} as UserPronoResult[][]) && userPronoResults.map((result, index) => {
                        return (
                        <Link to={`/${convertToUrl(auth.data.m_oPartner.m_sName)}/${result[0].m_oProno.m_oEvent.m_sUrlNL}/${result[0].m_oProno.m_iPronoID}`} key={index}>
                        <div className='flex flex-col w-full gap-4 p-4 duration-200 rounded-lg cursor-pointer bg-white/10 hover:shadow-lg hover:scale-105'>
                            <div className='flex flex-wrap items-start justify-between gap-4'>
                                <div>
                                    <p>{getTranslationString(i18n.language.toUpperCase() as Language, result[0].m_oProno, 'm_sDescriptionNL')}</p>
                                    {/* <p>{result[0].m_oProno.m_sDescriptionNL}</p> */}
                                    <div className='flex items-center gap-1 text-xs opacity-50'>
                                        <Clock className='w-3'/>
                                        <p>{isValid(new Date(result[0].m_oProno.m_dtStart)) && format(new Date(result[0].m_oProno.m_dtStart), "d MMMM, yyyy")}</p>
                                    </div>
                                </div>

                                <div className={`${result[0].m_oProno.m_bActive ? (result[0].m_oProno.m_bClosed ? 'bg-red-400/20' : 'bg-teal-400/20') : 'bg-orange-400/20'} bg-orange-400/20 w-fit rounded-full px-2 font-medium`}>
                                    <p className={`${result[0].m_oProno.m_bActive ? (result[0].m_oProno.m_bClosed ? 'text-red-400' : 'text-teal-400') : 'text-orange-400'}`}>
                                        {result[0].m_oProno.m_bActive
                                        ?   <>
                                            {
                                                result[0].m_oProno.m_bClosed ? t("profile.closed") : t("profile.open")
                                            }
                                            </>
                                        : t("profile.expired")
                                        }
                                    </p>
                                </div>
                            </div>
                            
                            {/* Jouw keuze */}
                            <div>
                                <p className='mb-2 font-medium underline'>{t("profile.your_choice")}</p>
                                <div className='grid grid-rows-3 gap-4 sm:flex'>
                                    <div>
                                        <p className='text-sm opacity-70'>{t("profile.first_place")}</p>
                                        <p>{result.find(i=>i.m_sPosition.toString()==='1')?.m_oCompetitor?.m_sName}</p>
                                    </div>
                                    <div>
                                        <p className='text-sm opacity-70'>{t("profile.second_place")}</p>
                                        <p>{result.find(i=>i.m_sPosition.toString()==='2')?.m_oCompetitor?.m_sName}</p>
                                    </div>
                                    <div>
                                        <p className='text-sm opacity-70'>{t("profile.third_place")}</p>
                                        <p>{result.find(i=>i.m_sPosition.toString()==='3')?.m_oCompetitor?.m_sName}</p>
                                    </div>
                                </div>  
                            </div>  
                            
                            {/* Resultaat */}
                            {/* <div className='hidden'>
                                <p className='mb-2 font-medium underline'>Resultaat</p>
                                <div className='flex gap-4'>
                                    <div>
                                        <p className='text-sm opacity-70'>1e plaats</p>
                                        <p>Jasper Philipsen</p>
                                    </div>
                                    <div>
                                        <p className='text-sm opacity-70'>2e plaats</p>
                                        <p>Wout van Aert</p>
                                    </div>
                                    <div>
                                        <p className='text-sm opacity-70'>3e plaats</p>
                                        <p>Yves Lampaert</p>
                                    </div>
                                </div>  
                            </div>                */}
                        </div>
                        </Link>
                        )
                    })}

                    {userPronoResults === 'empty' &&
                    <div>
                        <p>{t("profile.no_predictions")}</p>
                    </div>
                    }
                </div>            
            </div>
        </div>
        
        <div className='fixed top-0 left-0 w-full h-full bg-gray-800 '>
            <img className='object-cover w-full h-full opacity-10' src={bg} alt="background tifogame"/>
        </div>
        
        <Footer className='relative z-10 bg-tifo/60 backdrop-blur-sm'/>

    </div>

    </>
  )
}

export default Profile