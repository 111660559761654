import { Checkbox } from '../../components/ui/checkbox';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import React, { useState, useEffect } from 'react'

import boxlogo from '../../assets/logo/boxlogo.svg'

import { RootState } from '../../redux/index'
import { useSelector, useDispatch } from 'react-redux'
import { setLoginPopup, setUserdata } from '../../redux/actions';
import { Check, Facebook, Loader2, MoveRight } from 'lucide-react';

import { motion as m, AnimatePresence } from 'framer-motion'
import { useMutation } from '@tanstack/react-query';
import axios from '../../lib/axios';
import toast, { Toaster } from 'react-hot-toast';
import useAuth from '../../hooks/useAuth';
import { getCookie, setCookie } from '../../lib/cookies';
import { addDays } from 'date-fns';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import mergeRefs, { delay } from '../../lib/utils';
import { useClickAway } from '@uidotdev/usehooks';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';

import { jwtDecode } from "jwt-decode";
import { useTranslation } from 'react-i18next';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { AxiosError } from 'axios';

type ForgotPasswordComponentProps = {
  onClickLogin: () => void
}

const ForgotPasswordComponent : React.FC<ForgotPasswordComponentProps> = ({onClickLogin}) => {
  const { t } = useTranslation()
  const [email, setemail] = useState('');

  const {mutate, isPending, reset, isSuccess} = useMutation({
    mutationFn: async () => {
        await axios.post(`password-reset`, { username: email })
        toast.success(t('login.o_password.request_sent'), {id:'fp'})
        return true
    },
    onError: (e) => {
      toast.error(t('login.o_password.request_error'), {id:'fp'})
      setTimeout(()=>{ reset() }, 2000)
    }
  })

  return (
    <>
    <Toaster />
    <div className='flex flex-col items-center gap-4'>
      <div className='flex items-center justify-center w-16 h-16 border rounded-full bg-neutral-50'><img className='w-8' src={boxlogo} alt="Logo"/></div>
      <div className='flex flex-col items-center'>
        <h2 className='text-xl font-semibold'>{t('login.o_password.forgot_password')}</h2>
        <p>{t('login.o_password.fill_details')}</p>
      </div>
    </div>

      <div className='w-full h-px my-4 bg-neutral-200'></div>

      <div className='flex flex-col w-full gap-4'>
        <div className='w-full'>
          <Label htmlFor='email'>{t('login.o_login.email')}</Label>
          <Input onKeyDown={(e)=>{ if(e.key==='Enter' && !isPending && email.length > 0 && !isSuccess){ mutate() } }} value={email} onChange={({currentTarget})=>{setemail(currentTarget.value)}} id='email' placeholder={`${t('login.o_login.email')}...`}/>
        </div>
      </div>

      <Button onClick={()=>{mutate()}} disabled={isPending || email.length<2 || isSuccess} className='w-full my-4 group'>
        {isSuccess && <Check className='w-4 h-4 mr-2'/>}
        {isPending ? <Loader2 className='w-4 animate-spin'/> : <>{t('login.o_password.btn_request')} {!isSuccess && <MoveRight className='ml-2 duration-200 group-hover:ml-4' />}</>}
      </Button>

      <div className='flex gap-1'>
        <p className='text-sm'>{t('login.o_password.back_login')}</p>
        <button onClick={onClickLogin} className='text-sm font-semibold underline'>{t('login.o_password.click_here')}</button>
      </div>
    </>
  )
}

type LoginComponentProps = {
  onClickRegister: () => void;
  onClickForgot: () => void
}

const LoginComponent : React.FC<LoginComponentProps> = ({onClickRegister, onClickForgot}) => {
  const dispatch = useDispatch()
  const {t, i18n} = useTranslation()
  const {setAuth, persist, setpersist} = useAuth()

  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')

  const [screen, setscreen] = useState<'default' | 'message'>('default');

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      try {
        const {data} = await axios.post(`authenticate`, {username:email.toLowerCase(), password})
        localStorage.setItem('authToken', data)
        const {data:me} = await axios.get(`me`)
        if(persist) {
          setCookie('email', email, addDays(new Date(), 7))
        }
        setAuth({data:me, status:'authenticated'})
        i18n.changeLanguage(me.m_sLanguage.toLowerCase())
        dispatch(setLoginPopup(false))
      } catch (error) {
        if(error.response.status===404){setscreen('message')}

        if(error.response.status === 400){ 
          if(error instanceof AxiosError){ 
            if(error.response.data.toLowerCase().includes('password')){
              toast.error(`${t("error.400_authenticate_post-1")}`, {duration:5000}); return;
            }
          }
          toast.error(`${t("error.400_authenticate_post")}`, {duration:5000}); return;
        }
        if(error.response.status === 401){ 
          toast.error(`${t("error.401_authenticate_post")}`, {duration:5000}); return;
        }
        if(error.response.status === 500){ 
          toast.error(`${t("error.500_authenticate_post")}`, {duration:5000}); return;
        }
        
        toast.error(t("login.o_login.toast_error_login"), {id:'login'})
      }
    }
  })

  useEffect(() => {
    const email = getCookie('email')
    if(email){setemail(email)}

    function pressEnter(e) { 
      if(e.key!=='Enter'){return}
      mutate()
    }
    window.addEventListener("keypress", pressEnter)

    return () => {
      window.removeEventListener("keypress", pressEnter)
    }
  }, [])

  const onClickCreateNew = () => {
    window.sessionStorage.setItem('email', email)
    window.sessionStorage.setItem('password', password)
    onClickRegister()
  }
  
  return (
    <>
    <Toaster toastOptions={{id:'login'}}/>
    {/* Login Screen  */}
    {screen === 'default' && 
    <>
      <div className='flex flex-col items-center gap-4'>
        <div className='flex items-center justify-center w-16 h-16 border rounded-full bg-neutral-50'><img className='w-8' src={boxlogo} alt="Logo"/></div>
        <div className='flex flex-col items-center'>
          <h2 className='text-xl font-semibold'>{t("login.o_login.title")}</h2>
          <p>{t("login.o_login.description")}</p>
        </div>
      </div>

      <div className='w-full h-px my-4 bg-neutral-200'></div>

      <div className='flex flex-col w-full gap-4'>
        <div className='w-full'>
          <Label htmlFor='email'>{t("login.o_login.email")}</Label>
          <Input value={email} onChange={({currentTarget})=>{setemail(currentTarget.value)}} id='email' placeholder={t("login.o_login.email")+'...'}/>
        </div>

        <div className='w-full'>
          <Label htmlFor='password'>{t("login.o_login.password")}</Label>
          <Input value={password} onChange={({ currentTarget }) => { setpassword(currentTarget.value) }} id='password' placeholder={t("login.o_login.password")+'...'} type='password'/>
        </div>
      </div>

      <div className='flex justify-between w-full gap-8 mt-4'>
        <div className='flex items-center gap-1'>
          <Checkbox checked={persist} onCheckedChange={(v)=>{setpersist(v as boolean)}} id='remember'/>
          <Label htmlFor='remember'>{t("login.o_login.remember")}</Label>
        </div>

        <button onClick={()=>{onClickForgot()}} className='text-sm font-medium text-right underline'>{t("login.o_password.forgot_password")}</button>
      </div>

      <Button onClick={()=>{mutate()}} disabled={isPending} className='w-full my-4 group'>{isPending ? <Loader2 className='w-4 animate-spin'/> : <>{t("login.o_login.btn_continue")} <MoveRight className='ml-2 duration-200 group-hover:ml-4' /></>}</Button>
      {/* <div className='flex gap-1'>
        <p className='text-sm'>Nog geen account?</p>
        <button onClick={onClickRegister} className='text-sm font-semibold underline'>Registreer hier</button>
      </div> */}
    </>
    }

    {/* Old Database Message */}
    {screen === 'message' && 
    <>
    <div className='flex flex-col items-center gap-4'>
      <div className='flex items-center justify-center w-16 h-16 border rounded-full bg-neutral-50'><img className='w-8' src={boxlogo} alt="Logo"/></div>
      <div className='flex flex-col items-center gap-2'>
        <h2 className='text-xl font-semibold'>{t("login.o_error.title")}</h2>
        <p className='text-sm text-center whitespace-pre-line'>{t("login.o_error.description")}</p>
        <p className='text-sm text-center whitespace-pre-line'>{t("login.o_error.try_again")}</p>
        <Button onClick={()=>{setscreen('default')}} variant='link' size='sm' className='text-xs'>{t("login.o_login.btn_retry")}</Button>
      </div>
      {/* Old database message */}
      {/* <div className='flex flex-col items-center'>
        <h2 className='text-xl font-semibold'>{t("login.o_login.error_title")}</h2>
        <p className='text-sm text-center'>{t("login.o_login.error_description")}</p>
        <div className='flex flex-col mt-4'>
          <Button onClick={()=>{onClickCreateNew()}}>{t("login.o_login.btn_create")}</Button>
          <Button onClick={()=>{setscreen('default')}} variant='link' size='sm' className='text-xs'>{t("login.o_login.btn_retry")}</Button>
        </div>
      </div> */}
    </div>
    </>
    }
    </>
  )
}

type RegisterComponentProps = {
  onClickLogin: () => void;
}

const RegisterComponent : React.FC<RegisterComponentProps> = ({onClickLogin}) => {
  const {setAuth} = useAuth()
  const dispatch = useDispatch()

  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [language, setlanguage] = useState('NL');
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');

  const { mutate:createAccount, isPending:isCreatingAccount } = useMutation({
    mutationFn: async () => {
      try {
        await axios.post(`users`, {
          email: email,
          username: email,
          firstname: firstname,
          lastname: lastname,
          language: language,
          password: password
        })
        toast.success('Succesvol geregistreerd!')

        const {data:auth} = await axios.post(`authenticate`, {username:email, password})
        localStorage.setItem('authToken', auth)

        const {data:me} = await axios.get(`me`)
        setAuth({data:me, status:'authenticated'})
        

        setCookie('email', email, addDays(new Date(), 7))
        dispatch(setLoginPopup(false))
      } catch (error) {
        toast.error('Er liep iets mis bij het aanmaken van uw account', {duration:5000})
        console.log(error)
      }
    }
  })

  useEffect(()=>{
    const e = window.sessionStorage.getItem('email')
    if(e){ setemail(e); window.sessionStorage.removeItem('email') }
    const p = window.sessionStorage.getItem('password')
    if(p){ setpassword(p); window.sessionStorage.removeItem('password') }
  }, [])

  function isComplete() {
    let complete = false;
    if(firstname.length>0&&lastname.length>0&&language.length>0&&email.length>0&&password.length>0){
      complete = true
    }
    return complete
  }

  const onSuccessGoogle = (res:CredentialResponse) => {
    const decoded:GoogleJWT = jwtDecode(res.credential)
    if(decoded.family_name){setlastname(decoded.family_name) }
    if(decoded.given_name){setfirstname(decoded.given_name)}
    if(decoded.email){setemail(decoded.email)}
  }

  
  

  return (
    <>
    <Toaster />
    <div className='flex flex-col items-center gap-4'>
      <div className='flex items-center justify-center w-16 h-16 border rounded-full bg-neutral-50'><img className='w-8' src={boxlogo} alt="Logo"/></div>
      <div className='flex flex-col items-center'>
        <h2 className='text-xl font-semibold'>Registreren</h2>
        <p>Gelieve uw gegevens in te vullen.</p>
      </div>
    </div>

    <div className='w-full h-px my-4 bg-neutral-200'></div>

    <div className=''>
      <GoogleLogin onSuccess={onSuccessGoogle} onError={()=>{}} text="signup_with" />

      <FacebookLogin
        appId="1120113495888217"
        onFail={(e)=>{console.log(e)}}
        onProfileSuccess={(p)=>{console.log(p)}}
        onSuccess={(r)=>{console.log(r)}}
        style={{
          backgroundColor: '#4267b2',
          color: '#fff',
          fontSize: '16px',
          padding: '12px 24px',
          border: 'none',
          borderRadius: '4px',
        }}
      />

      <div className='w-full h-px my-4 bg-neutral-200'></div>
    </div>

    <div className='flex flex-col w-full gap-4'>
      <div className='grid grid-cols-2 gap-4'>
        <div className='w-full'>
          <Label htmlFor='firstname'>Voornaam</Label>
          <Input value={firstname} onChange={({currentTarget})=>{setfirstname(currentTarget.value)}} id='firstname' placeholder='Voornaam...'/>
        </div>

        <div className='w-full'>
          <Label htmlFor='lastname'>Achternaam</Label>
          <Input value={lastname} onChange={({currentTarget})=>{setlastname(currentTarget.value)}} id='lastname' placeholder='Achternaam...'/>
        </div>
      </div>

      <div className='w-full'>
        <Label htmlFor='language'>Taal</Label>
        <Select value={language} onValueChange={(v)=>{setlanguage(v)}}>
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value="NL">Nederlands</SelectItem>
              <SelectItem value="EN">English</SelectItem>
              <SelectItem value="FR">Français</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      <div className='w-full'>
        <Label htmlFor='email'>Email</Label>
        <Input value={email} onChange={({currentTarget})=>{setemail(currentTarget.value)}} id='email' placeholder='Email...'/>
      </div>

      <div className='w-full'>
        <Label htmlFor='password'>Wachtwoord</Label>
        <Input value={password} onChange={({currentTarget})=>{setpassword(currentTarget.value)}} id='password' placeholder='Wachtwoord...' type='password'/>
      </div>
    </div>

    <Button disabled={!isComplete()} onClick={()=>{createAccount()}} className='w-full my-4 group'>
      {isCreatingAccount 
      ? <><Loader2 className='mr-2 animate-spin'/> Even geduld...</>
      : <>Registereren <MoveRight className='ml-2 duration-200 group-hover:ml-4'/></>}
    </Button>
    <div className='flex gap-1'>
      <p className='text-sm'>Al een account?</p>
      <button onClick={onClickLogin} className='text-sm font-semibold underline'>Log hier in</button>
    </div>
    </>
  )
}

const Login = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const loginPopup = useSelector((state:RootState) => state.loginPopup)

  const [screen, setscreen] = useState<Screens>('login');
  const ref = useClickAway((e)=>{
    const el = e.target as HTMLElement
    if(el.tagName==='HTML' || el.tagName==='SPAN' || el.role==='option'){ return; }
    dispatch(setLoginPopup(false))
  })

  if(loginPopup){
    return (
      <div className='fixed z-50 flex items-center justify-center w-full h-full bg-gradient-to-t from-black/90 to-white/20'>
        <div ref={mergeRefs(ref)} className='bg-white rounded-xl shadow-lg p-4 flex flex-col items-center w-fit max-w-lg mx-4 relative overflow-hidden sm:min-w-[400px]'>
          <button onClick={()=>{setscreen('login'); dispatch(setLoginPopup(false))}} className='absolute text-sm underline left-2 top-2 text-neutral-400 hover:text-neutral-600'>{t("login.close")}</button>
          
          <AnimatePresence mode='wait'>
          {screen === 'login' &&    <m.div key='0' className='w-full' initial={{opacity:0, x:-100}} animate={{opacity:1, x:0}} exit={{opacity:0, x:100}}><LoginComponent onClickForgot={()=>setscreen('forgot')} onClickRegister={()=>{setscreen('register')}}/></m.div>}
          {screen === 'register' && <m.div key='1' className='w-full' initial={{opacity:0, x:-100}} animate={{opacity:1, x:0}} exit={{opacity:0, x:100}}><RegisterComponent onClickLogin={()=>{setscreen('login')}}/></m.div>}
          {screen === 'forgot' && <m.div key='2' className='w-full' initial={{opacity:0, x:-100}} animate={{opacity:1, x:0}} exit={{opacity:0, x:100}}><ForgotPasswordComponent onClickLogin={()=>{setscreen('login')}}/></m.div>}
          </AnimatePresence>
        </div>
        
      </div>
    )
  } else {
    return (<></>)
  }
}

export default Login

type Screens = 'login' | 'register' | 'forgot'