import { Outlet } from "react-router-dom";
import Navbar from "../layouts/Navbar";
import { DepartmentProvider } from "../context/DepartmentProvider";
import { CookieProvider } from "../context/CookieProvider";

const Layout : React.FC = () => {
    return (
        <CookieProvider>
            <DepartmentProvider>
                <main className="flex flex-col">
                    <Navbar />
                    <Outlet />
                </main>
            </DepartmentProvider>
        </CookieProvider>
    )
}

export default Layout;