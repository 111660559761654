export function getTranslationString<T>(language:Language, obj:T, prefixKey?:keyof T) {
    try {
    let prefix = prefixKey ? prefixKey.toString().substring(0, prefixKey.toString().length - 2) : ''
    let key: string | undefined;

    switch (language) {
        case "NL":
            if(prefix){ key = prefix+"NL" }
            else { key = Object.keys(obj).find(k => k.endsWith("NL")); }
            break;
        case "FR":
            if(prefix){ key = prefix+"FR" }
            else { key = Object.keys(obj).find(k => k.endsWith("FR")); }
            break;
        case "EN":
            if(prefix){ key = prefix+"EN" }
            else { key = Object.keys(obj).find(k => k.endsWith("EN")); }
            break;
        case "DE":
            if(prefix){ key = prefix+"DE" }
            else { key = Object.keys(obj).find(k => k.endsWith("DE")); }
            break;
        default:
            break;
    }

    return (obj && key) ? (obj[key] ? obj[key] : undefined) : undefined;
    } catch (e){
        return undefined
    }
}

export function returnTranslationObject<T extends { m_sLangCode:string }>(language:Language, arr:T[], key?:keyof T):string {
    try {
        const translationObject = arr?.find(i => i.m_sLangCode.toUpperCase() === language)
        if(key && translationObject){ 
            if(typeof translationObject[key] === 'string') {
                return translationObject[key] as string
            }
            return ''
        }
        return ''
    } catch (e) {
        return ''
    }
}