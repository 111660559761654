import React, { useEffect, useState } from 'react'
import { Label } from '../../components/ui/label'
import { Combobox } from '../../components/ui/combobox'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table';
import { useQuery } from '@tanstack/react-query';
import axios from '../../lib/axios';
import toast, { Toaster } from 'react-hot-toast';
import { eventsURL } from '../../lib/fetch';
import useAuth from '../../hooks/useAuth';
import empty from '../../assets/images/illustrations/empty-cycling.svg'
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from '../../components/ui/carousel';
import EmblaCarousel from 'embla-carousel'
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/ui/button';
import { Link } from 'react-router-dom';
import { Bike } from 'lucide-react';
import Footer from '../../layouts/Footer';
import { getTranslationString } from '../../lib/translation';
import { cn } from '../../lib/utils';

interface RankingProps {
    competition?: string;
}

const RankingCompany = ({competition}:RankingProps) => {
    const {t} = useTranslation()
    const {auth} = useAuth()

    const {data:ranking, isFetching} = useQuery<EventRanking[] | null>({
        queryKey: [`ranking_company_${competition}`],
        queryFn: async () => {
            try {
                const {data} = await axios.get(`ranking?eventid=${competition}&partnerid=${auth?.data?.m_oPartner?.m_iPartnerID}`)
                if(Array.isArray(data)){ return data }
                return null
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: !!competition
    })

    return (
        <>{(!ranking && !isFetching && competition) 
            ? <div className='flex flex-col items-center justify-center'>
                <img className='max-w-xs mb-5' src={empty} alt="empty" />
                <p className='text-lg font-semibold text-neutral-500'>{t("ranking.no_ranking_title")}</p>
                <p className='text-sm text-neutral-400'>{t("ranking.no_ranking_desc")}</p>
            </div> 
            :
            <Table className='border rounded-md'>
                <TableHeader className='bg-slate-200'>
                    <TableRow className='bg-slate-200'>
                        <TableHead className='px-2 py-4 sm:px-4 w-14'>{t("ranking.position")}</TableHead>
                        <TableHead className='max-w-[100px] py-4 px-0 sm:px-4'>{t("ranking.name")}</TableHead>
                        <TableHead className='hidden px-2 py-4 sm:block sm:px-4'>{t("ranking.group")}</TableHead>
                        <TableHead className='w-16 px-2 py-4 text-right sm:px-4'>{t("ranking.points")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {!isFetching ? (ranking && ranking.map((rank,i)=>{
                        return (
                            <TableRow className={`${i===0&&'font-semibold'} ${rank.m_iUserID === auth?.data?.m_iUserID && '!text-green-700 font-semibold'} bg-tifo-darker hover:bg-tifo-light/10`} key={i}>
                                <TableCell className='px-2 sm:py-6 sm:px-4'>{i+1}</TableCell>
                                <TableCell className='max-w-[100px] truncate sm:py-6 px-0 sm:px-4 whitespace-nowrap'>{rank.m_sUser || 'Anoniempje'} {rank.m_iUserID === auth?.data?.m_iUserID && `(${t("ranking.you")})`}</TableCell>
                                <TableCell className='hidden px-2 sm:block sm:py-6 sm:px-4'>{rank.m_sDepartment}</TableCell>
                                <TableCell className='px-2 text-right sm:py-6 sm:px-4'>{rank.m_iPoints}</TableCell>
                            </TableRow>
                        )
                    })) 
                    :
                    <TableRow><TableCell colSpan={99}>{t("ranking.loading")}</TableCell></TableRow>
                    }              
                </TableBody>
            </Table>
        }</>
    )
}

const RankingDepartment = ({competition}:RankingProps) => {
    const {t} = useTranslation()
    const {auth} = useAuth()
    const {data:ranking, isFetching} = useQuery<EventRanking[] | null>({
        queryKey: [`ranking_group_${competition}`],
        queryFn: async () => {
            try {
                const {data} = await axios.get(`ranking?eventid=${competition}&departmentid=${auth.data.m_oDepartment.m_iDepartmentID}`)
                if(Array.isArray(data)){ return data }
                return null
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: !!competition
    })

    return (
        <>{(!ranking && !isFetching && competition) 
            ? <div className='flex flex-col items-center justify-center'>
                <img className='max-w-xs mb-5' src={empty} alt="empty" />
                <p className='text-lg font-semibold text-neutral-500'>{t("ranking.no_ranking_title")}</p>
                <p className='text-sm text-neutral-400'>{t("ranking.no_ranking_desc")}</p>
            </div> 
            :
            <Table className='border rounded-md'>
                <TableHeader className='bg-slate-200'>
                    <TableRow className='bg-slate-200'>
                        <TableHead className='py-4'>{t("ranking.position")}</TableHead>
                        <TableHead className='py-4'>{t("ranking.name")}</TableHead>
                        <TableHead className='py-4'>{t("ranking.group")}</TableHead>
                        <TableHead className='py-4'>{t("ranking.points")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {!isFetching ? (ranking && ranking.map((rank,i)=>{
                        return (
                            <TableRow className={`${i===0&&'font-semibold'} ${rank.m_iUserID === auth?.data?.m_iUserID && '!text-green-700 font-semibold'} bg-tifo-darker hover:bg-tifo-light/10`} key={i}>
                                <TableCell className='sm:py-6'>{i+1}</TableCell>
                                <TableCell className='sm:py-6 whitespace-nowrap'>{rank.m_sUser} {rank.m_iUserID === auth?.data?.m_iUserID && `(${t("ranking.you")})`}</TableCell>
                                <TableCell className='sm:py-6'>{rank.m_sDepartment}</TableCell>
                                <TableCell className='sm:py-6'>{rank.m_iPoints}</TableCell>
                            </TableRow>
                        )
                    })) 
                    :
                    <TableRow><TableCell colSpan={99}>{t("ranking.loading")}</TableCell></TableRow>
                    }              
                </TableBody>
            </Table>
        }</>
    )
}

const RankingGroups = ({competition}:RankingProps) => {
    const {t} = useTranslation()
    const {auth} = useAuth()

    interface GroupsRanking {
        m_iDepartmentID: number;
        m_sDepartment:   string;
        m_iPoints:       number;
    }

    const {data:ranking, isFetching} = useQuery<GroupsRanking[] | null>({
        queryKey: [`ranking_groups_${competition}`],
        queryFn: async () => {
            try {
                const { data } = await axios.get(`ranking?eventid=${competition}&partnerid=${auth?.data?.m_oPartner?.m_iPartnerID}`);
                
                if (Array.isArray(data)) { 
                    let result: Record<number, { m_iDepartmentID: number; m_sDepartment: string; totalPoints: number; count: number }> = {};
    
                    data.forEach(obj => {
                        const departmentID = obj.m_iDepartmentID;
    
                        if (!result[departmentID]) {
                            result[departmentID] = {
                                m_iDepartmentID: departmentID,
                                m_sDepartment: obj.m_sDepartment,
                                totalPoints: 0,
                                count: 0
                            };
                        }
    
                        result[departmentID].totalPoints += obj.m_iPoints;
                        result[departmentID].count += 1;
                    });
    
                    // Convert to array and compute average points
                    const sortedResult = Object.values(result)
                        .map(dept => ({
                            m_iDepartmentID: dept.m_iDepartmentID,
                            m_sDepartment: dept.m_sDepartment,
                            m_iPoints: Math.round((dept.totalPoints / dept.count)*100)/100, // Calculate average
                        }))
                        .sort((a, b) => b.m_iPoints - a.m_iPoints);
    
                    return sortedResult;
                }
                return null;
            } catch (error) {
                console.log(error);
                return null;
            }
        },
        enabled: !!competition
    })

    return (
        <>{(!ranking && !isFetching && competition) 
            ? <div className='flex flex-col items-center justify-center'>
                <img className='max-w-xs mb-5' src={empty} alt="empty" />
                <p className='text-lg font-semibold text-neutral-500'>{t("ranking.no_ranking_title")}</p>
                <p className='text-sm text-neutral-400'>{t("ranking.no_ranking_desc")}</p>
            </div> 
            :
            <Table className='border rounded-md'>
                <TableHeader className='bg-slate-200'>
                    <TableRow className='bg-slate-200'>
                        <TableHead className='py-4'>{t("ranking.position")}</TableHead>
                        <TableHead className='py-4'>{t("ranking.name")}</TableHead>
                        <TableHead className='py-4'>{t("ranking.points")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {!isFetching ? (ranking && ranking.map((rank,i)=>{
                        return (
                            <TableRow className={`${i===0&&'font-semibold'} ${(rank?.m_iDepartmentID === auth?.data?.m_oDepartment?.m_iDepartmentID) && '!text-green-700 font-semibold'} bg-tifo-darker hover:bg-tifo-light/10`} key={i}>
                                <TableCell className='sm:py-6'>{i+1}</TableCell>
                                <TableCell className='sm:py-6 whitespace-nowrap'>{rank.m_sDepartment}</TableCell>
                                <TableCell className='sm:py-6'>{rank.m_iPoints}</TableCell>
                            </TableRow>
                        )
                    })) 
                    :
                    <TableRow><TableCell colSpan={99}>{t("ranking.loading")}</TableCell></TableRow>
                    }              
                </TableBody>
            </Table>
        }</>
    )
}

const Ranking = () => {
    const {auth} = useAuth()
    const {t, i18n} = useTranslation()
    const [selected_type, setselected_type] = useState('company');
    const [selected_competition, setselected_competition] = useState<string>();

    const { data:departments } = useQuery({
        queryKey: [`departments`],
        queryFn: async () => {
            const { data } = await axios.get(`departments?partnerid=${auth.data.m_oPartner.m_iPartnerID}`)
            if(!Array.isArray(data)){throw new Error('No array')}
            return data;
        }
    })

    const { data } = useQuery<PartnerEvent[] | null>({
        queryKey: [`events`],
        queryFn: async () => {
          try {
            const {data} = await axios.get(`partners/${auth.data.m_oPartner.m_iPartnerID}/events`)
            if(!Array.isArray(data)){throw new Error('No array')}
            let result = data.filter(i=>i.m_bActive===true).reverse()
            if(result.length===1){ setselected_competition(result[0].m_oEvent.m_iEventID.toString()) }
            return result
          } catch (error) {
            console.log(error);
            return null
          }
        },
        enabled: !!auth.data
    })

    EmblaCarousel.globalOptions = { watchDrag: false }
    const [api, setApi] = useState<CarouselApi>()

    const latestOpenEvent = data ? data.find(i=>i.m_oEvent.m_bClosed === false) : undefined

    
    const language = i18n.language.toUpperCase() as Language;
    const eventOptions = data?.map(event => ({ value: event.m_oEvent.m_iEventID, label: getTranslationString(language, event.m_oEvent, 'm_sNameNL') || event.m_oEvent.m_sNameNL })) || [];

    return (<><Toaster />
    <div className='w-full h-[88px] bg-tifo'></div>
        <div className='relative py-16 group'>
            <div className='relative z-[1] max-w-5xl mx-auto px-4 flex flex-col gap-4 text-white'>
                <h2 className='text-3xl font-bold md:text-6xl'>{t("ranking.cta")}</h2>
                <p className='text-lg'>{t("ranking.cta_description")}</p>
                {latestOpenEvent && 
                <Link to={(data && latestOpenEvent.m_oEvent && latestOpenEvent?.m_oEvent?.m_sUrlNL) ? latestOpenEvent?.m_oEvent?.m_sUrlNL : ''} className='mt-4 w-fit'>
                    <Button variant='tifo' className='gap-2'>
                        {t("ranking.cta_button")} <Bike className='w-4 h-4'/>
                    </Button>
                </Link>}
            </div>
            <div className='absolute top-0 left-0 w-full h-full overflow-hidden bg-tifo'>
                {data && latestOpenEvent && latestOpenEvent.m_oEvent && latestOpenEvent.m_oEvent.m_sImageUrl && <img src={latestOpenEvent.m_oEvent.m_sImageUrl} alt={latestOpenEvent.m_oEvent.m_sNameNL} className='object-cover w-full h-full duration-1000 opacity-20 group-hover:scale-105'/>}
            </div>
        </div>
        <div className='flex flex-col items-center bg-slate-50'> 
        <div className='w-full max-w-5xl px-4 py-8 sm:py-16'>
            <h1 className='max-w-4xl text-2xl font-bold leading-tight sm:text-4xl'>{t("ranking.title")}</h1>

            <div className='flex flex-wrap-reverse items-center justify-between gap-4 p-2 my-8 bg-white rounded-lg shadow-lg sm:p-8'>
                <div className='flex flex-col gap-1'>
                    <Label className='ml-2 font-semibold uppercase text-neutral-600' htmlFor='competition'>{t("ranking.competition")}</Label>
                    <Combobox selectedValue={selected_competition} onValueChange={(v)=>{setselected_competition(v.value.toString())}} options={eventOptions} className='min-w-[250px] rounded-full' placeholder={t("ranking.competition_placeholder")} input_placeholder={t("ranking.search_placeholder")} id='competition'/>
                </div>

                <div className={cn('grid items-center gap-2 bg-white border h-10 rounded-full p-1 backdrop-blur-sm',
                    departments?.length > 1 ? 'grid-cols-3' : 'grid-cols-2'
                )}>
                    <button onClick={()=>{api.scrollTo(0); setselected_type('company') }} className={`${selected_type === 'company' && '!bg-tifo-light !text-tifo'} flex justify-center items-center gap-2 text-tifo text-sm font-medium h-full rounded-full px-4 hover:bg-neutral-100 duration-200`}>{t("ranking.company")}</button>
                    {(departments?.length > 1) && <button onClick={()=>{api.scrollTo(1); setselected_type('department') }} className={`${selected_type === 'department' && '!bg-tifo-light !text-tifo'} flex justify-center items-center gap-2 text-tifo text-sm font-medium h-full rounded-full px-4 hover:bg-neutral-100 duration-200`}>{t("ranking.department")}</button>}
                    <button onClick={()=>{api.scrollTo(2); setselected_type('groups') }} className={`${selected_type === 'groups' && '!bg-tifo-light !text-tifo'} flex justify-center items-center gap-2 text-tifo text-sm font-medium h-full rounded-full px-4 hover:bg-neutral-100 duration-200`}>{t("ranking.groups")}</button>
                </div>
            </div>

            <Carousel setApi={setApi} opts={{watchDrag:false}}>
                <CarouselContent>
                    <CarouselItem>
                        <RankingCompany competition={selected_competition}/>
                    </CarouselItem>
                    <CarouselItem>
                        <RankingDepartment competition={selected_competition}/>
                    </CarouselItem>
                    <CarouselItem>
                        <RankingGroups competition={selected_competition}/>
                    </CarouselItem>
                </CarouselContent>
            </Carousel>
            
        </div>
    </div>

    <Footer />
    </>
  )
}

export default Ranking