import React from 'react'
import { Route, Routes } from 'react-router-dom'
import NL from './nl'
import FR from './fr'
import EN from './en'
import DE from './de'

const RulesImages = () => {
  return (
    <Routes>
        <Route path="nl" element={<NL />}/>
        <Route path="fr" element={<FR />}/>
        <Route path="en" element={<EN />}/>
        <Route path="de" element={<DE />}/>
    </Routes>
  )
}

export default RulesImages