import { useTranslation } from 'react-i18next'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectGroup, SelectItem } from '../../components/ui/select'
import { CheckIcon } from 'lucide-react'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu'
import { cn } from '../../lib/utils'
import { useEffect, useState } from 'react'

const defaultLanguages = ['nl', 'en', 'fr', 'de']

type Props = {
    languages?: string[]
    trigger?: React.ReactNode
    selectTriggerClassName?: string;
}

const PartnerLanguagePicker = ( {languages=defaultLanguages, trigger, selectTriggerClassName}: Props ) => {
    const [langs, setlangs] = useState<string[]>(defaultLanguages);
    
    const {i18n} = useTranslation()

    useEffect(() => {
        if(languages.length > 0) {
          setlangs(languages)
          if(languages.length === 1) {
            i18n.changeLanguage(languages[0])
          }
        }
    }, [])

    if (trigger) {
        return (
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    {trigger}
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    {langs?.includes('nl') && <DropdownMenuItem onSelect={()=>{ i18n.changeLanguage('nl') }}>Nederlands {i18n.language === 'nl' && <CheckIcon className='w-4 h-4 ml-2'/>}</DropdownMenuItem>}
                    {langs?.includes('en') && <DropdownMenuItem onSelect={()=>{ i18n.changeLanguage('en') }}>English {i18n.language === 'en' && <CheckIcon className='w-4 h-4 ml-2'/>}</DropdownMenuItem>}
                    {langs?.includes('fr') && <DropdownMenuItem onSelect={()=>{ i18n.changeLanguage('fr') }}>Français {i18n.language === 'fr' && <CheckIcon className='w-4 h-4 ml-2'/>}</DropdownMenuItem>}
                    {langs?.includes('de') && <DropdownMenuItem onSelect={()=>{ i18n.changeLanguage('de') }}>Deutsch {i18n.language === 'de' && <CheckIcon className='w-4 h-4 ml-2'/>}</DropdownMenuItem>}
                </DropdownMenuContent>
            </DropdownMenu>
        )
    }
     
  return (
    <Select onValueChange={(v)=>{ i18n.changeLanguage(v.toLowerCase()) }} value={i18n.language.toLowerCase()}>
        <SelectTrigger className={cn('text-white bg-transparent border-white rounded-md w-fit', selectTriggerClassName)}>
          <SelectValue placeholder="Selecteer een taal..."/>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {langs?.includes('nl') && <SelectItem value="nl">Nederlands</SelectItem> }
            {langs?.includes('en') && <SelectItem value="en">English</SelectItem> }
            {langs?.includes('fr') && <SelectItem value="fr">Français</SelectItem> }
            {langs?.includes('de') && <SelectItem value="de">Deutsch</SelectItem> }
          </SelectGroup>
        </SelectContent>
    </Select>
  )
}

export default PartnerLanguagePicker