import React, { useState, useEffect, useRef } from 'react'
import Joyride, { CallBackProps, Locale, STATUS, Step, Styles } from 'react-joyride';
import { isEqual } from 'lodash'

import { Award, Bike, Check, HelpCircle, Save, Scroll, Search, View, X } from 'lucide-react'
import { Button } from '../../ui/button'
import ProfilePicture from '../../riders/ProfilePicture'
import { Input } from '../../ui/input'
import mascotte from '../../../assets/images/gadgets/tifo_mascotte.svg';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table'

import flags from '../../../styles/components/general/Flags.module.scss'

import ridersData from '../../../data/riders.json'
import { filterObjectsByValue } from '../../../lib/filter';
import { setLoginPopup } from '../../../redux/actions';
import { RootState } from '../../../redux';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../lib/axios'
import useAuth from '../../../hooks/useAuth';
import toast, { Toaster } from 'react-hot-toast';
import { getImageObject } from 'src/lib/images';
import useTable, { ColumnSort } from '../../../hooks/useTable';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '../../../components/ui/alert-dialog';

import CircularSlider from 'react-circular-slider-svg';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../components/ui/hover-card';

import trophyGold from '../../../assets/images/illustrations/trophee-gold.svg'
import trophySilver from '../../../assets/images/illustrations/trophee-silver.svg'
import trophyBronze from '../../../assets/images/illustrations/trophee-bronze.svg'
import { useTranslation } from 'react-i18next';
import { isBefore } from 'date-fns';
import { Popover, PopoverContent, PopoverTrigger } from '../../../components/ui/popover';
import anonymousRider from '../../../assets/images/riders/anonymous_rider.png'

import { isMobile } from 'react-device-detect';
import { useClickAway } from '@uidotdev/usehooks';
import mergeRefs from '../../../lib/utils';

import {useDrag, DragPreview, useDrop } from 'react-aria';
import { getTranslationString } from '../../../lib/translation';
import { AxiosError } from 'axios';
import { getCycloPronotypeConfig } from '../../../config/cyclogame-pronotype-config';
import { getCycloPartnerConfig } from '../../../config/cyclogame-partner-config';

interface TabsProps {
    value: string;
    onValueChange: (value:string) => void;
}

const Tabs : React.FC<TabsProps> = ({value, onValueChange}) => {
    const { auth } = useAuth();
    const {t} = useTranslation()

    const show_tabs = getCycloPartnerConfig(auth.data.m_oPartner.m_iPartnerID)?.prono?.view_rider_tabs

    useEffect(()=>{
        if(!show_tabs){ onValueChange('rest') }
    }, [])

    if(!show_tabs) {
        return (
            <div className='w-full mt-8 border-b border-white/40'>
                <button onClick={()=>{onValueChange('rest')}} className={`${value === 'rest' && '!text-white border-white'} border-b-2 border-transparent px-3 py-1 font-medium duration-200 text-white/40`}>{t("prono.all")}</button>
            </div>
        )
    }
    else {
        return (
            <div className='w-full mt-8 border-b border-white/40'>
                <button onClick={()=>{onValueChange('favorites')}} className={`${value === 'favorites' && '!text-white border-white'} border-b-2 border-transparent px-3 py-1 font-medium duration-200 text-white/40`}>{t("prono.favorites")}</button>
                <button onClick={()=>{onValueChange('outsiders')}} className={`${value === 'outsiders' && '!text-white border-white'} border-b-2 border-transparent px-3 py-1 font-medium duration-200 text-white/40`}>{t("prono.outsiders")}</button>
                <button onClick={()=>{onValueChange('rest')}} className={`${value === 'rest' && '!text-white border-white'} border-b-2 border-transparent px-3 py-1 font-medium duration-200 text-white/40`}>{t("prono.all")}</button>
            </div>
        )
    }
}

const KiesRiderButton = ({value, picked_riders, prono, onPickRider, updateSorting, sorting}:{value:PronoCompetitors, picked_riders:RidersPodium, prono:Prono, sorting:any, onPickRider:any, updateSorting:any}) => {
    const {t} = useTranslation()
    function isPositionAvailable(pos:number) {
        let isAvailable = true;
        switch (pos) {
            case 1:
                if(picked_riders.one){ isAvailable = false }
                break;
            case 2:
                if(picked_riders.two){ isAvailable = false }
                break;
            case 3:
                if(picked_riders.three){ isAvailable = false }
                break;
            default:
                break;
        }
        return isAvailable
    }

    const [isPressing, setIsPressing] = useState(false);
    const timeoutRef = useRef<number | null>(null);

    const handleTouchStart = () => {        
        // Set a timeout to execute the function after 1 second
        // if("vibrate" in navigator){
        //     navigator.vibrate(30)
        // }
        
        timeoutRef.current = window.setTimeout(() => {
            setIsPressing(true);
        }, 250);
    };

    const handleTouchEnd = () => {       
        // Clear the timeout if the button is released before 1 second
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
    };

    const trophyMenu = useClickAway(()=>{ if(isPressing){ setIsPressing(false) } })

    // return (
    //     <button onClick={(e)=>{ if(!prono?.m_bClosed){onPickRider(value)} }} className={`text-tifo-light font-medium hover:underline py-3`}>{t("prono.pick")}</button>
    // )

    return (
        <>
        {!isMobile
        ?
        <HoverCard>
            <HoverCardTrigger asChild><button onClick={()=>{ if(!prono?.m_bClosed){onPickRider(value)} }} className={`rounded-full py-3 md:px-4 text-tifo-light font-medium hover:underline`}>{t("prono.pick")}</button></HoverCardTrigger>
            <HoverCardContent className='flex items-center gap-1 p-2 rounded-full w-fit'>
                <button onClick={()=>{if(!prono?.m_bClosed){updateSorting(sorting); onPickRider(value, 1)} }} disabled={!isPositionAvailable(1)} className='w-[40px] h-[40px] min-w-[40px] bg-neutral-100 rounded-full border border-neutral-200 flex justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed select-none'><img className={`${isPositionAvailable(1) && 'hover:scale-150'} w-7 h-7 duration-300`} src={trophyGold} alt="trophy gold"/></button>
                <button onClick={()=>{if(!prono?.m_bClosed){updateSorting(sorting); onPickRider(value, 2)} }} disabled={!isPositionAvailable(2)} className='w-[40px] h-[40px] min-w-[40px] bg-neutral-100 rounded-full border border-neutral-200 flex justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed select-none'><img className={`${isPositionAvailable(2) && 'hover:scale-150'} w-7 h-7 duration-300`} src={trophySilver} alt="trophy silver"/></button>
                <button onClick={()=>{if(!prono?.m_bClosed){updateSorting(sorting); onPickRider(value, 3)} }} disabled={!isPositionAvailable(3)} className='w-[40px] h-[40px] min-w-[40px] bg-neutral-100 rounded-full border border-neutral-200 flex justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed select-none'><img className={`${isPositionAvailable(3) && 'hover:scale-150'} w-7 h-7 duration-300`} src={trophyBronze} alt="trophy bronze"/></button>
            </HoverCardContent>
        </HoverCard>
        :
        <Popover open={isPressing}>
            <PopoverTrigger><div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onTouchCancel={handleTouchEnd} onClick={()=>{ if(!prono?.m_bClosed){onPickRider(value)} }} className={`${isPressing && '!text-tifo-light/50'} py-3 text-tifo-light font-medium select-none p-4`}>{t("prono.pick")}</div></PopoverTrigger>
            <PopoverContent side='top' ref={mergeRefs(trophyMenu)} className='flex items-center gap-1 p-2 rounded-full w-fit'>
                <button onClick={()=>{if(!prono?.m_bClosed){setIsPressing(false); updateSorting(sorting); onPickRider(value, 1)} }} disabled={!isPositionAvailable(1)} className='w-[40px] h-[40px] min-w-[40px] bg-neutral-100 rounded-full border border-neutral-200 flex justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed select-none'><img className={`${isPositionAvailable(1) && 'hover:scale-150'} w-7 h-7 duration-300`} src={trophyGold} alt="trophy gold"/></button>
                <button onClick={()=>{if(!prono?.m_bClosed){setIsPressing(false); updateSorting(sorting); onPickRider(value, 2)} }} disabled={!isPositionAvailable(2)} className='w-[40px] h-[40px] min-w-[40px] bg-neutral-100 rounded-full border border-neutral-200 flex justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed select-none'><img className={`${isPositionAvailable(2) && 'hover:scale-150'} w-7 h-7 duration-300`} src={trophySilver} alt="trophy silver"/></button>
                <button onClick={()=>{if(!prono?.m_bClosed){setIsPressing(false); updateSorting(sorting); onPickRider(value, 3)} }} disabled={!isPositionAvailable(3)} className='w-[40px] h-[40px] min-w-[40px] bg-neutral-100 rounded-full border border-neutral-200 flex justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed select-none'><img className={`${isPositionAvailable(3) && 'hover:scale-150'} w-7 h-7 duration-300`} src={trophyBronze} alt="trophy bronze"/></button>
            </PopoverContent>
        </Popover>
        
        }
        </>
    )
}

type TableRowProps = {
    value?: PronoCompetitors
    riders: PronoCompetitors[],
    picked_riders: RidersPodium,
    onPickRider: (rider: PronoCompetitors, position?: number) => void;
    onRemoveRider: (rider: PronoCompetitors) => void;
    prono?: Prono;
    updateSorting?: (newSorting:ColumnSort) => void
    sorting?: ColumnSort
    index?: number
}
const TableRowItem = ({picked_riders, riders, value, onRemoveRider, onPickRider, updateSorting, prono, sorting, index}:TableRowProps) => {
    const {t} = useTranslation()
    const [screenWidth, setscreenWidth] = useState(0);

    useEffect(() => {
        setscreenWidth(window.innerWidth)
    
        const updateMedia = () => {
            setscreenWidth(window.innerWidth)
        };
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    const dragPreview = useRef(null)
    let { dragProps, isDragging } = useDrag({
        preview: dragPreview,
        getItems() {
            return ([
                {'image': value.m_oCompetitor.m_sImageUrl},
                {'rider': JSON.stringify(value)}
            ])
        },
        getAllowedDropOperations() {
            return ['copy']
        }
    });

    function onClickRow() {
        if(!prono?.m_bClosed){
            if(Object.values(picked_riders).flat().includes(value) ){
                //Remove rider
                onRemoveRider(value);
            }
            else {
                //Add rider
                onPickRider(value)
            }
        }
    }

    //let dProps = Object.values(picked_riders).flat().includes(value) ? null : {...dragProps}
    let dProps = null

    return (
        <>    
        <TableRow {...dProps} onClick={()=>{onClickRow()}} className={`hover:bg-transparent group bg-transparent text-white cursor-pointer border-none ${isDragging ? '' : ''}`}>
            <TableCell className={`${isMobile ? 'py-0' : 'py-3'} rounded-l-full group-hover:bg-white/10`}>
                <DragPreview ref={dragPreview}>
                    {(items) => {
                        return(
                        <div style={{transform:'translate(0,0)'}} className='relative w-[60px] h-[60px] rounded-full border-2 border-tifo-light/50 !bg-neutral-600 overflow-hidden translate-x-0 translate-y-0 opacity-[.999]'>
                            <img src={value.m_oCompetitor.m_sImageUrl} className='object-cover w-full h-full'/>
                        </div>
                    )}}
                </DragPreview>
                {screenWidth > 768 
                    ?   <p className={`${(!Object.values(picked_riders).flat().includes(value)) && 'cursor-pointer hover:underline'}`} onClick={()=>{ if(!Object.values(picked_riders).flat().includes(value) && !prono?.m_bClosed){ onPickRider(value) } }}>{value.m_oCompetitor.m_sName}</p>
                    :   <div className='flex items-center gap-4'>
                            <span className={`${flags[value.m_oCompetitor.m_sNationality.toLowerCase()]} ${flags['flag']}`}></span> 
                            <div>
                                <p>{value.m_oCompetitor.m_sName}</p>
                                <p className='text-xs opacity-50'>{value.m_oCompetitor.m_sTeam}</p>
                            </div>
                        </div>
                }
                <img src={value.m_oCompetitor.m_sImageUrl} className='sr-only'/>
            </TableCell>
            {riders?.every(i=>i.m_oCompetitor.m_sTeam==='')
            ? <></>
            :
            <>
            {screenWidth > 768 && <TableCell className='py-3 group-hover:bg-white/10'>
                {value.m_oCompetitor.m_sTeam}                         
                </TableCell>}
            </>
            }
            {screenWidth > 768 && <TableCell className='py-3 group-hover:bg-white/10'><span className={`${flags[value.m_oCompetitor.m_sNationality.toLowerCase()]} ${flags['flag']}`}></span></TableCell>}
            <TableCell onClick={(e)=>{e.stopPropagation();}} className={`p-0 flex justify-end rounded-r-full group-hover:bg-white/10`}>
                {!prono?.m_bClosed && 
                <>
                {Object.values(picked_riders).flat().includes(value) 
                    ? <div className='flex items-center justify-end -translate-x-1'><button onClick={()=>{onRemoveRider(value)}} className={`p-3 pr-0 steps_remove_rider text-red-400 font-semibold hover:underline`}>{t("prono.remove")}</button></div>
                    : <i className={index === 3 ? 'steps_pick_rider' : ''}><KiesRiderButton sorting={sorting} updateSorting={updateSorting} value={value} picked_riders={picked_riders} prono={prono} onPickRider={onPickRider}/></i>
                }
                </>
                }
            </TableCell>
        </TableRow>
        </>
    )
}

interface TableRidersProps {
    riders: PronoCompetitors[],
    picked_riders: RidersPodium,
    onPickRider: (rider: PronoCompetitors, position?: number) => void;
    onRemoveRider: (rider: PronoCompetitors) => void;
    prono?: Prono
}

const TableRiders : React.FC<TableRidersProps> = ({riders, picked_riders, prono, onPickRider, onRemoveRider}) => {
    const {data, sorting, filter, updateFilter, updateSorting} = useTable({data:riders, sorting:{desc:false, id:'m_oCompetitor.m_sName'}})
    const {t} = useTranslation()
    // Sorting to make picked riders first
    let ridersWithoutPodium = data.filter((rider) => rider.m_oCompetitor.m_sName !== picked_riders?.one?.m_oCompetitor.m_sName && rider.m_oCompetitor.m_sName !== picked_riders.two?.m_oCompetitor.m_sName && rider.m_oCompetitor.m_sName !== picked_riders.three?.m_oCompetitor.m_sName)
    let ridersPodiumFirst = [...Object.values(picked_riders).flat(), ...ridersWithoutPodium]
    
    const [screenWidth, setscreenWidth] = useState(1600);

    useEffect(() => {
        setscreenWidth(window.innerWidth)
    
        const updateMedia = () => {
            setscreenWidth(window.innerWidth)
        };
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    return (
        <Table onClick={(e)=>{e.stopPropagation(); }}> 
            <TableHeader>
                <TableRow className='border-none bg-white/20 hover:bg-white/20'>
                    <TableHead onClick={()=>{updateSorting({id:'m_oCompetitor.m_sName', desc:!sorting.desc})}} className='h-10 text-white rounded-l-full cursor-pointer hover:underline'>{t("prono.name")}</TableHead>
                    <>
                    {riders?.every(i=>i.m_oCompetitor.m_sTeam==='' && screenWidth > 768) 
                    ? <></> 
                    : <>{screenWidth > 768 && <TableHead onClick={()=>{updateSorting({id:'m_oCompetitor.m_sTeam', desc:!sorting.desc})}} className='h-10 text-white cursor-pointer hover:underline'>{t("prono.team")}</TableHead>}</>}
                    </>
                    {screenWidth > 768 && <TableHead onClick={()=>{updateSorting({id:'m_oCompetitor.m_sNationality', desc:!sorting.desc})}} className='h-10 text-white cursor-pointer hover:underline'>{t("prono.nationality")}</TableHead>}
                    <TableHead className='h-10 rounded-r-full'><span className='sr-only'>{riders?.length}</span></TableHead>
                </TableRow>
            </TableHeader>
            <TableBody onClick={(e)=>{e.stopPropagation()}}>
                {ridersPodiumFirst && ridersPodiumFirst.map((value, index) => {
                    return (
                        <TableRowItem key={index} index={index} picked_riders={picked_riders} riders={riders} prono={prono} sorting={sorting} updateSorting={updateSorting} value={value} onRemoveRider={(v)=>onRemoveRider(v)} onPickRider={(v, p)=>onPickRider(v, p)}/>
                    )
                })}  
            </TableBody>
        </Table>
    )
}

type SaveButtonProps = {
    prono: Prono;
    saved_riders: RidersPodium
    picked_riders: RidersPodium
    savePodium: () => void;
}

const SaveButton = ({prono, saved_riders, picked_riders, savePodium}:SaveButtonProps) => {
    const {t, i18n} = useTranslation()
    const {auth} = useAuth()

    const [open, setopen] = useState(false);
    const defaultSpeed = getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.min
    const [speedValue, setspeedValue] = useState<number>(defaultSpeed);

    const { data:tiebreaker } = useQuery<TiebreakerAnswer | null>({
        queryKey: [`tiebreaker_${prono?.m_iPronoID}`],
        queryFn: async () => {
          try {
            const { data } = await axios.get(`tie-breaker?pronoid=${prono.m_iPronoID}`)
            if(!data){ throw new Error() }
            if(parseFloat(data.m_nUserAnswer) > -1){
                if(data.m_nUserAnswer < getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.min){ setspeedValue(defaultSpeed); }
                else { setspeedValue(data.m_nUserAnswer) }                
            }
            return data
          } catch (error) {
            console.log(error);
            return null
          }
        },
        enabled: !!prono
    })

    const hasTieBreaker = !!tiebreaker;

    function returnSaveButtonLabel() {
        let label:any = <><Save strokeWidth={1.5} className='w-5'/>{t("prono.btn_save")}</>

        if(prono.m_bClosed){ label = t("prono.btn_closed") }
        else if((isBefore(new Date(prono?.m_dtStart), new Date)) && (isEqual(picked_riders, saved_riders) && saved_riders.one)){ label = <><Check strokeWidth={1.5} className='w-5'/>{t("prono.btn_progress")}</>}
        else if(isBefore(new Date(prono?.m_dtStart), new Date)){ label = <><Bike strokeWidth={1.5} className='w-5'/>{t("prono.btn_progress")}</> }
        else if(isEqual(picked_riders, saved_riders) && saved_riders.one){ label = <><Check strokeWidth={1.5} className='w-5'/>{t("prono.btn_saved")}</> }
        else if(!picked_riders.one && Object.keys(picked_riders).length>0){ label = t("prono.btn_winner") }
        
        return label
    }

    function isDisabled() {
        if((isBefore(new Date(prono?.m_dtStart), new Date)) && (isEqual(picked_riders, saved_riders) && saved_riders.one)){ return true }
        if(isBefore(new Date(prono?.m_dtStart), new Date)){ return true }
        if(isEqual(picked_riders, saved_riders) && saved_riders.one){ return true }
        return !picked_riders.one || prono?.m_bClosed || isBefore(new Date(prono?.m_dtStart), new Date)
    }

    async function handleSave() {
        if(hasTieBreaker){
            try {
                await axios.post(`tie-breaker`, {
                    pronoid: prono.m_iPronoID,
                    answer: speedValue
                })
            } catch (error) {
                console.error('Tiebreaker saving failed')
                console.log(error);
            }
        }

        savePodium()
    }

    return (
        <AlertDialog open={open} onOpenChange={(o)=>{if(auth.status==='authenticated' && hasTieBreaker){ console.log(o); setopen(o) }}}>
            <AlertDialogTrigger asChild>
                <Button disabled={isDisabled()} onClick={()=>{ if(auth.status!=='authenticated' || (auth.status==='authenticated'&&!hasTieBreaker)){ savePodium() } }} variant={(isEqual(picked_riders, saved_riders) && saved_riders.one) ? 'tifo_outline' : 'tifo'} className='gap-2 duration-200 steps_save_selection whitespace-nowrap'>
                    {returnSaveButtonLabel()}
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
               
                <p className='font-bold text-center font-lg'>{t("prono.o_tiebreaker.title")}</p>
                <p className='text-lg font-medium text-center text-neutral-600'>{(auth.data && tiebreaker) ? (getTranslationString(i18n.language as Language, tiebreaker.m_oQuestion, "m_sQuestionNL") || t("prono.o_tiebreaker.description")) : ''}</p>
                <div className='relative flex items-center justify-center'>
                    <input className='absolute w-20 text-lg font-bold text-center -translate-x-1/2 translate-y-1/2 border-b number-hide-buttons top-1/2 left-1/2 focus:outline-none' 
                        autoFocus
                        value={(Math.round(speedValue*100)/100)} 
                        max={getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.max || 70}
                        onChange={({currentTarget})=>{ 
                            setspeedValue(parseFloat(currentTarget.value)) 
                            
                        }} 
                        onBlur={()=>{
                            if(speedValue > getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.max){ setspeedValue(getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.max) }
                            if(speedValue < getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.min){ setspeedValue(getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.min) }
                        }}
                        type="number" 
                    />
                    <p className='absolute bottom-0 text-sm font-medium -translate-x-1/2 left-1/2 text-neutral-300'>km/u</p>
                    <div className='h-[120px] overflow-hidden'>
                        <CircularSlider 
                            handle1={{
                                value:(
                                    (speedValue < getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.min || isNaN(speedValue)) 
                                        ? getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.min 
                                        : (speedValue > getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.max
                                            ? getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.max
                                            : speedValue)
                                ), 
                                onChange: v=>{setspeedValue(v)}
                            }} 
                            arcColor='#42E097' 
                            arcBackgroundColor='#f3f4f6' 
                            startAngle={90} 
                            trackWidth={10} 
                            minValue={getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.min || 0} 
                            maxValue={getCycloPronotypeConfig(prono.m_iPronoTypeID).tiebreaker.max || 70} 
                            endAngle={270}
                        />
                    </div>
                </div>
                

                <AlertDialogFooter>
                    <AlertDialogCancel>{t("prono.o_tiebreaker.cancel")}</AlertDialogCancel>
                    <AlertDialogAction onClick={()=>{ handleSave() }}>{t("prono.o_tiebreaker.save")}</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

interface GameProps {
    run_game_steps: boolean;
    setrun_game_steps: React.Dispatch<React.SetStateAction<boolean>>;
    prono?: Prono;
    competitors?: PronoCompetitors[] | null
    event: EventClass
    onEnterData?: () => void;
    onStartTutorial?: () => void;
}

const Game : React.FC<GameProps> = ({run_game_steps, setrun_game_steps, onEnterData, onStartTutorial, prono, competitors, event}) => {
    const dispatch = useDispatch();
    const {auth} = useAuth() 
    const {t} = useTranslation()

    const [main_riders, setmain_riders] = useState<PronoCompetitors[]>();
    const [riders_list, setriders_list] = useState<RidersList>();
    const [tmp_save_riders, settmp_save_riders] = useState<RidersPodium>({});
    const [picked_riders, setpicked_riders] = useState<RidersPodium>({});
    const [saved_riders, setsaved_riders] = useState<RidersPodium>({});

    const [search_input, setsearch_input] = useState('');
    const [show_quickpick, setshow_quickpick] = useState(true);

    const [active_riders_list, setactive_riders_list] = useState<RiderType>('favorites');

    const steps: Step[] = [
        {
            target: '.steps_pick_rider',
            content: (
                <div>
                    <h3 className='mb-2 text-lg font-semibold'>{t("prono.o_help.o_prono.pick_rider")}</h3>
                    <p className='text-sm'>{t("prono.o_help.o_prono.pick_rider_desc")}</p>
                </div>
            ),
            disableBeacon: true,
        },
        {
            target: '.steps_remove_rider',
            content: (
                <div>
                    <h3 className='mb-2 text-lg font-semibold'>{t("prono.o_help.o_prono.remove_rider")}</h3>
                    <p className='text-sm'>{t("prono.o_help.o_prono.remove_rider_desc")}</p>
                </div>
            ),
            disableBeacon: true,
        },
        {
            target: '.steps_quick_pick',
            content: (
                <div>
                    <h3 className='mb-2 text-lg font-semibold'>{t("prono.o_help.o_prono.quickpick")}</h3>
                    <p className='text-sm'>{t("prono.o_help.o_prono.quickpick_desc")}</p>
                </div>
            ),
            disableBeacon: true,
        },
        {
            target: '.steps_save_selection',
            content: (
                <div>
                    <h3 className='mb-2 text-lg font-semibold'>{t("prono.o_help.o_prono.save")}</h3>
                    <p className='text-sm'>{t("prono.o_help.o_prono.save_desc")}</p>
                </div>
            ),
            disableBeacon: true,
        }
    ]

    useQuery({
        queryKey: [`prevPicked_${prono.m_iPronoID}`],
        queryFn: async () => {
            try {
                const {data} = await axios.get(`user-prono-results?pronoid=${prono.m_iPronoID}&userid=${auth.data.m_iUserID}`)
                let results = data.filter(i=>parseFloat(i.m_sPosition)>0)
                let obj:RidersPodium = {}
                for (let i = 0; i < results.length; i++) {
                    if(parseFloat(results[i].m_sPosition)===1){
                        obj.one = results[i] as PronoCompetitors
                    }
                    else if(parseFloat(results[i].m_sPosition)===2){
                        obj.two = results[i] as PronoCompetitors
                    }
                    else if(parseFloat(results[i].m_sPosition)===3){
                        obj.three = results[i] as PronoCompetitors
                    }
                }
                setpicked_riders(obj); setsaved_riders(obj)
            } catch (error) { }
            return null
        },
        enabled: !!prono && !!auth.data
    })

    useEffect(()=>{
        const timer = setInterval(()=>{
            if(Object.keys(picked_riders).length === 0){
                console.log("show quickpick")
                setshow_quickpick(true)
            }
            else { clearInterval(timer) }
        }, 10000)
        return () => { clearInterval(timer) }
    }, [picked_riders])

    useEffect(()=>{
        let timer;
        if(show_quickpick){
            timer = setTimeout(()=>{
                setshow_quickpick(false)
            }, 5000)
        }
        return () => { clearTimeout(timer) }
    }, [show_quickpick])

    useEffect(()=>{
        if(run_game_steps){
            settmp_save_riders(picked_riders)
            quickPick()
        }
    }, [run_game_steps])

    useEffect(()=>{
        if(!competitors) return;

        let fav = competitors.filter(i=>i.m_bIsFavorite === true)
        const out = competitors.filter(i => i.m_bIsOutsider === true)
        const res = competitors

        if(fav.length===0){
            setactive_riders_list('outsiders')
            if(out.length===0){
                setactive_riders_list('rest')
            }
        }

        setriders_list({favorites:fav, outsiders:out, rest:res})
        setmain_riders(competitors)
    }, [competitors])

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, action } = data;

        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

        if (finishedStatuses.includes(status) || action === "close") {
            setrun_game_steps(false)
            setpicked_riders(tmp_save_riders)
            settmp_save_riders({})
        }
    }

    const filterAndSortRiders = (ridersArray:PronoCompetitors[], recursionDepth: number = 0) => {
        const MAX_RECURSION_DEPTH = 2;
        if (recursionDepth > MAX_RECURSION_DEPTH) {
            console.error("Maximum recursion depth reached.");
            return [];
        }

        let result:PronoCompetitors[] = [...ridersArray];

        //Filter based on inputfield
        result = filterObjectsByValue(result, search_input) as PronoCompetitors[]

        //Sort based on picked options (picked options first)
        let ridersWithoutPodium = result.filter((rider) => rider.m_oCompetitor.m_sName !== picked_riders?.one?.m_oCompetitor.m_sName && rider.m_oCompetitor.m_sName !== picked_riders.two?.m_oCompetitor.m_sName && rider.m_oCompetitor.m_sName !== picked_riders.three?.m_oCompetitor.m_sName)
        let ridersPodiumFirst = [...Object.values(picked_riders).flat(), ...ridersWithoutPodium]

        if(ridersWithoutPodium.length > 0 || ridersPodiumFirst.some(i=>i.m_oCompetitor.m_sName.replace(/\s/g, '').toLowerCase().includes(search_input.replace(/\s/g, '').toLowerCase()))) { 
            
            return ridersPodiumFirst as PronoCompetitors[]; 
        }

        const allRidersResult = filterAndSortRiders(competitors, recursionDepth + 1)
        if(allRidersResult && allRidersResult.length > 0) { 
            let ridersWithoutPodium = allRidersResult.filter((rider) => rider.m_oCompetitor.m_sName !== picked_riders?.one?.m_oCompetitor.m_sName && rider.m_oCompetitor.m_sName !== picked_riders.two?.m_oCompetitor.m_sName && rider.m_oCompetitor.m_sName !== picked_riders.three?.m_oCompetitor.m_sName)
            let ridersPodiumFirst = [...Object.values(picked_riders).flat(), ...ridersWithoutPodium]
            return ridersPodiumFirst as PronoCompetitors[]
        }

        return []
    }

    const pickRider = (newRider:PronoCompetitors, position?:number) => {
        const { one, two, three } = picked_riders;
        let updatedPodium;

        if(position){
            switch (position) {
                case 1:
                    if(one){ return; }
                    updatedPodium = { ...picked_riders, one: newRider };
                    break;
                case 2:
                    if(two){ return; }
                    updatedPodium = { ...picked_riders, two: newRider };
                    break;
                case 3:
                    if(three){ return; }
                    updatedPodium = { ...picked_riders, three: newRider };
                    break;
                default:
                    break;
            }
            setpicked_riders(updatedPodium)
            return;
        }
        
        if (!one) {
            updatedPodium = { ...picked_riders, one: newRider };
          } else if (!two) {
            updatedPodium = { ...picked_riders, two: newRider };
          } else if (!three) {
            updatedPodium = { ...picked_riders, three: newRider };
          } else {
            toast(t("prono.toast_full_podium"), {id:'pickRider'})
            // The podium is already full, you can handle this case as needed.
            // For example, you could replace one of the existing riders, or return the currentPodium unchanged.
            updatedPodium = picked_riders;
        }
        setpicked_riders(updatedPodium)
    }

    const removeRider = (riderToRemove:PronoCompetitors) => {
        let updatedPodium = {...picked_riders}
        if (picked_riders.one === riderToRemove) {
            delete updatedPodium.one
          } else if (picked_riders.two === riderToRemove) {
            delete updatedPodium.two
          } else if (picked_riders.three === riderToRemove) {
            delete updatedPodium.three
          } else {
            // The rider to remove was not found on the podium, so just return the picked_riders unchanged.
        }
        setpicked_riders(updatedPodium)
    }

    const quickPick = () => {
        if(riders_list){
            const updatedPodium = addRandomRiderToPodium(picked_riders, riders_list);
            setpicked_riders(updatedPodium);
        }        
    }

    function keyToPos(k:string) {
        switch (k) {
            case 'one':
                return 1
            case 'two':
                return 2
            case 'three':
                return 3
            default:
                break;
        }
    }

    const savePodium = async () => {
        if(auth.data){
            setsaved_riders(picked_riders)

            try {
                let results = Object.keys(picked_riders).map((key)=>({position:keyToPos(key), competitorid:picked_riders[key].m_iCompetitorID}))
                const {data} = await axios.post(`user-prono-results`, {
                    pronoid: prono.m_iPronoID,
                    results: results,
                    eventid: event.m_iEventID
                })
                onEnterData()
            } catch (error) {
                setsaved_riders({})

                if(error instanceof AxiosError){
                    if(error.response.status === 400){ 
                        if(error.response.data.toLowerCase().includes('results not found')){
                            toast.error(`${t("error.400_userpronoresults_post")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('event id not supplied')){
                            toast.error(`${t("error.400_userpronoresults_post-2")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('answer is empty')){
                            toast.error(`${t("error.400_userpronoresults_post-3")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('prono id not supplied')){
                            toast.error(`${t("error.400_userpronoresults_post-4")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('event id required')){
                            toast.error(`${t("error.400_userpronoresults_post-5")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        toast.error(`${t("error.400_userpronoresults_post")}`, {duration:5000, id:'savePodium'}); return;
                    }
                    if(error.response.status === 401){ 
                        toast.error(`${t("error.401_userpronoresults_post")}`, {duration:5000, id:'savePodium'}); return;
                    }
                    if(error.response.status === 422){ 
                        toast.error(`${t("error.422_userpronoresults_post")}`, {duration:5000, id:'savePodium'}); return;
                    }
                    if(error.response.status === 403){ 
                        if(error.response.data.toLowerCase().includes('user inactive')){
                            toast.error(`${t("error.403_userpronoresults_post")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('department inactive')){
                            toast.error(`${t("error.403_userpronoresults_post-2")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('prono inactive')){
                            toast.error(`${t("error.403_userpronoresults_post-3")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('already closed')){
                            toast.error(`${t("error.403_userpronoresults_post-4")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('event inactive')){
                            toast.error(`${t("error.403_userpronoresults_post-5")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('this partner')){
                            toast.error(`${t("error.403_userpronoresults_post-6")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('not linked')){
                            toast.error(`${t("error.403_userpronoresults_post-7")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('competitor inactive')){
                            toast.error(`${t("error.403_userpronoresults_post-8")}`, {duration:5000, id:'savePodium'}); return;
                        }
                    }
                    if(error.response.status === 404){
                        if(error.response.data.toLowerCase().includes('partner not found')){
                            toast.error(`${t("error.404_userpronoresults_post")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('department not found')){
                            toast.error(`${t("error.404_userpronoresults_post-2")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('prono not found')){
                            toast.error(`${t("error.404_userpronoresults_post-3")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('event not found')){
                            toast.error(`${t("error.404_userpronoresults_post-4")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('department not linked to the event')){
                            toast.error(`${t("error.404_userpronoresults_post-5")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('competitors not linked to the prono')){
                            toast.error(`${t("error.404_userpronoresults_post-6")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('competitor does not participate')){
                            toast.error(`${t("error.404_userpronoresults_post-7")}`, {duration:5000, id:'savePodium'}); return;
                        }
                        if(error.response.data.toLowerCase().includes('competitor not found')){
                            toast.error(`${t("error.404_userpronoresults_post-8")}`, {duration:5000, id:'savePodium'}); return;
                        }
                    }
                    if(error.response.status === 500){
                        toast.error(`${t("error.500_userpronoresults_post")}`, {duration:5000, id:'savePodium'}); return;
                    }
                }

                setsaved_riders({})
            }
        }
        else {
            dispatch(setLoginPopup(true))
        }
    }

    let dropFirst = useRef(null)
    let dropSecond = useRef(null)
    let dropThird = useRef(null)
    
    let { dropProps:dropPropsThird, isDropTarget:isDropTargetThird } = useDrop({
        ref: dropThird,
        async onDrop(e) {
            try {         
                let items = await Promise.all(e.items.map((item:any)=>item.getText('rider')))
                const rider = JSON.parse(items[0]) as PronoCompetitors
                pickRider(rider, 3)
            } catch (error) { console.log("dnd failed") }
        }
    })

    let { dropProps:dropPropsSecond, isDropTarget:isDropTargetSecond } = useDrop({
        ref: dropSecond,
        async onDrop(e) {
            try {         
                let items = await Promise.all(e.items.map((item:any)=>item.getText('rider')))
                const rider = JSON.parse(items[0]) as PronoCompetitors
                pickRider(rider, 2)
            } catch (error) { console.log("dnd failed") }
        }
    })

    let { dropProps:dropPropsFirst, isDropTarget:isDropTargetFirst } = useDrop({
        ref: dropFirst,
        async onDrop(e) {
            try {         
                let items = await Promise.all(e.items.map((item:any)=>item.getText('rider')))
                const rider = JSON.parse(items[0]) as PronoCompetitors
                pickRider(rider, 1)
            } catch (error) { console.log("dnd failed") }
        }
    })

    const stepsLocale:Locale = {
        back: t("prono.o_help.back"),
        next: t("prono.o_help.next"),
        skip: t("prono.o_help.skip"),
        last: t("prono.o_help.last")
    }

    const riderstableRef = useRef<HTMLDivElement | undefined>();
    function handleScrollRiders() {
        if(riderstableRef.current){
            riderstableRef.current.scrollIntoView({behavior:'smooth'})
        }
    }

    useEffect(()=>{
        const equal = (isEqual(picked_riders, saved_riders) && saved_riders.one) ? true : false

        const onBeforeUnload = (e) => {
            if (!equal) {
              e.preventDefault();
              e.returnValue = "";
              console.log("Keuzes niet opgeslagen")
            }
        };
        window.addEventListener("beforeunload", onBeforeUnload);
    
        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, [picked_riders])

  return (
    <>
    <Toaster />

    <Joyride run={run_game_steps} steps={steps} showSkipButton scrollToFirstStep showProgress continuous callback={handleJoyrideCallback} styles={stepsStyles} locale={stepsLocale}/>

    <div className='absolute top-0 flex items-center gap-4 -translate-y-1/2 left-4'>
        <div className='px-3 py-1 font-semibold uppercase shadow-sm bg-tifo text-tifo-light'>{t("prono.prono")}</div>
        <HelpContainer onStartTutorial={()=>{onStartTutorial && onStartTutorial()}}/>
    </div>
    
    <div className='grid grid-cols-3 gap-4'>
        <div {...dropPropsSecond} className='flex flex-col items-center justify-start text-white sm:justify-end'>
            <p className='mb-4 text-lg font-semibold'>2</p>
            <ProfilePicture onClick={()=>{ handleScrollRiders() }} className={`${isDropTargetSecond ? 'border-[#B5BAC5] scale-105' : ''} duration-200`} onDelete={() => { picked_riders.two && removeRider(picked_riders.two) }} showDelete={!isBefore(new Date(prono?.m_dtStart), new Date) && (typeof (picked_riders.two) === 'object')} image={picked_riders.two?.m_oCompetitor.m_sImageUrl} nationality={picked_riders.two?.m_oCompetitor.m_sNationality ? picked_riders.two?.m_oCompetitor.m_sNationality as Nationality : null} size='md'/>
            {typeof(picked_riders.two) === 'object' 
                ? <><p className='mt-4 font-medium text-center uppercase'>{picked_riders.two?.m_oCompetitor.m_sName}</p><p className='text-sm text-center opacity-50'>{picked_riders.two?.m_oCompetitor.m_sTeam}</p></>
                : (!isBefore(new Date(prono?.m_dtStart), new Date) && <p onClick={()=>{ handleScrollRiders() }} className='mt-4 font-medium text-center opacity-50 cursor-pointer'>{t("prono.pick_rider")}</p>)
            }
        </div>

        <div {...dropPropsFirst} className='flex flex-col items-center justify-start text-white sm:justify-end'>
            <p className='mb-4 text-lg font-semibold'>1</p>
            <ProfilePicture onClick={()=>{ handleScrollRiders() }} className={`${isDropTargetFirst ? 'border-[#FBD55D] scale-105' : ''} duration-200`} onDelete={() => { picked_riders.one && removeRider(picked_riders.one) }} showDelete={!isBefore(new Date(prono?.m_dtStart), new Date) && (typeof (picked_riders.one) === 'object')} image={picked_riders.one?.m_oCompetitor.m_sImageUrl} nationality={picked_riders.one?.m_oCompetitor.m_sNationality ? picked_riders.one?.m_oCompetitor.m_sNationality as Nationality : null} size='lg'/>
            {typeof(picked_riders.one) === 'object' 
                ? <><p className='mt-4 font-medium text-center uppercase'>{picked_riders.one?.m_oCompetitor.m_sName}</p><p className='text-sm text-center opacity-50'>{picked_riders.one?.m_oCompetitor.m_sTeam}</p></>
                : (!isBefore(new Date(prono?.m_dtStart), new Date) && <p onClick={()=>{ handleScrollRiders() }} className='mt-4 font-medium text-center opacity-50 cursor-pointer'>{t("prono.pick_rider")}</p>)
            }
        </div>

        <div {...dropPropsThird} className={`flex flex-col justify-start sm:justify-end items-center text-white`}>
            <p className='mb-4 text-lg font-semibold'>3</p>
            <ProfilePicture onClick={()=>{ handleScrollRiders() }} className={`${isDropTargetThird ? 'border-[#EC7E33] scale-105' : ''} duration-200`} onDelete={() => { picked_riders.three && removeRider(picked_riders.three) }} showDelete={!isBefore(new Date(prono?.m_dtStart), new Date) && (typeof (picked_riders.three) === 'object')} image={picked_riders.three?.m_oCompetitor.m_sImageUrl} nationality={picked_riders.three?.m_oCompetitor.m_sNationality ? picked_riders.three?.m_oCompetitor.m_sNationality as Nationality : null} size='md'/>
            {typeof(picked_riders.three) === 'object' 
                ? <><p className='mt-4 font-medium text-center uppercase'>{picked_riders.three?.m_oCompetitor.m_sName}</p><p className='text-sm text-center opacity-50'>{picked_riders.three?.m_oCompetitor.m_sTeam}</p></>
                : (!isBefore(new Date(prono?.m_dtStart), new Date) && <p onClick={()=>{ handleScrollRiders() }} className='mt-4 font-medium text-center opacity-50 cursor-pointer'>{t("prono.pick_rider")}</p>)
            }
        </div>
    </div>
    
    {!isBefore(new Date(prono?.m_dtStart), new Date) ? 
    <>

        <div className='flex flex-wrap items-end justify-between w-full gap-4 md:flex-nowrap'>
            <Tabs 
                value={active_riders_list} 
                onValueChange={(value)=>{
                    setsearch_input('')
                    setactive_riders_list(value as RiderType)
                }}
                />
            <SaveButton prono={prono} saved_riders={saved_riders} picked_riders={picked_riders} savePodium={()=>{savePodium()}}/>
        </div>

        {riders_list && 
        <>
        <div className='flex justify-between mt-4'>
            <Input onInput={({currentTarget})=>{setsearch_input(currentTarget.value)}} value={search_input} variant='transparent' placeholder={t("prono.placeholder_search")} className='w-fit' startEnhancer={<Search className='w-4'/>}/>
            <button onClick={()=>{quickPick()}} className='relative steps_quick_pick group'>
                <div className={`${show_quickpick && 'max-w-[250px] opacity-100'} max-w-[0px] overflow-hidden group-hover:max-w-[200px] duration-200 opacity-0 group-hover:opacity-100 absolute -bottom-2 translate-y-full md:bottom-1/2 md:translate-y-1/2 right-0 md:right-12 whitespace-nowrap bg-white/80 px-2 py-1 rounded-full uppercase font-semibold select-none`}>
                    {show_quickpick ? t("prono.tooltip_qp_help") : t("prono.tooltip_qp")}
                </div>
                <img src={mascotte} alt="Tifo mascotte" className={`${show_quickpick && 'wiggle'}`}/>
            </button>
        </div>
        <div className='mt-4' ref={riderstableRef}>
            <TableRiders prono={prono} onRemoveRider={(rider)=>{removeRider(rider)}} onPickRider={(rider, position)=>{pickRider(rider, position)}} riders={filterAndSortRiders(riders_list[active_riders_list])} picked_riders={picked_riders}/>
        </div>
        </>
        }
    </>
    :
    <>
    <div className='mt-8 text-white'>
        <div className='py-2 space-y-2 rounded-sm bg-white/10'>
            <p className='text-center'>{t('prono.prono_started')}</p>
            <p className='text-center'>{t('prono.prono_started_p2')}</p>
        </div>
    </div>
    
    </>
    }
    </>
  )
}

type RidersList = {
    favorites: PronoCompetitors[],
    outsiders: PronoCompetitors[],
    rest: PronoCompetitors[]
}

type RidersPodium = {
    one?: PronoCompetitors | null,
    two?: PronoCompetitors | null,
    three?: PronoCompetitors | null
}

type RiderType = 'favorites' | 'outsiders' | 'rest'

function getRandomIndex(max: number): number {
    return Math.floor(Math.random() * max);
}

function addRandomRiderToPodium(currentPodium: RidersPodium, ridersList: RidersList): RidersPodium {
    const { favorites, outsiders, rest } = ridersList;
    const podium: RidersPodium = { ...currentPodium };
  
    const tryToAddRider = (rider: PronoCompetitors) => {
      if (!podium.one) {
        podium.one = rider;
      } else if (!podium.two) {
        podium.two = rider;
      } else if (!podium.three) {
        podium.three = rider;
      }
    };
  
    const isRiderOnPodium = (rider: PronoCompetitors) => {
      return (
        rider === podium.one ||
        rider === podium.two ||
        rider === podium.three
      );
    };

    let availableFavorites = [...favorites];
    let availableRest = [...rest];
  
    while (!podium.one || !podium.two) {
      if (availableFavorites.length > 0) {
        const randomIndex = getRandomIndex(favorites.length);
        const randomFavorite = availableFavorites[randomIndex];
        if (!isRiderOnPodium(randomFavorite)) {
          tryToAddRider(randomFavorite);
        }
        availableFavorites.splice(randomIndex, 1); // Remove the chosen rider
      }
      else if(availableRest.length > 0) {
        //Pick a rest-rider when there are no favorites
        const randomIndex = getRandomIndex(availableRest.length);
        const randomRest = availableRest[randomIndex];
        if (!isRiderOnPodium(randomRest)) {
          tryToAddRider(randomRest);
        }
        availableRest.splice(randomIndex, 1);
      }
      // If there are no more riders left to pick from, break out of the loop
      break;
    }

    let availableOutsiders = [...outsiders];

    while (!podium.three) {    
        if (availableOutsiders.length > 0) {
          const randomIndex = getRandomIndex(availableOutsiders.length);
          const randomOutsider = availableOutsiders[randomIndex];
          if (!isRiderOnPodium(randomOutsider)) {
            tryToAddRider(randomOutsider);
          }
          availableOutsiders.splice(randomIndex, 1);
        }
        else if(availableRest.length > 0) {
            //Pick a rest-rider when there are no outsiders
            const randomIndex = getRandomIndex(availableRest.length);
            const randomRest = availableRest[randomIndex];
            if (!isRiderOnPodium(randomRest)) {
              tryToAddRider(randomRest);
            }
            availableRest.splice(randomIndex, 1);
        } else {
            break;  // No more riders left
        }
    }

    return podium;
}

const stepsStyles = {
    buttonNext: {
        backgroundColor: '#192324',
        fontSize:'15px',
        height:'40px',
        fontWeight:'500'
    },
    buttonBack: {
        color: '#192324',
        fontWeight:'500',
        fontSize:'15px'
    },
}

interface HelpContainerProps {
    onStartTutorial: () => void;
  }
  
  const HelpContainer : React.FC<HelpContainerProps> = ({onStartTutorial}) => {
    const {t} = useTranslation()
    return (
      <>
      <AlertDialog>
        <AlertDialogTrigger asChild><button className='flex items-center justify-center w-8 h-8 rounded-full bg-tifo-light text-tifo'><HelpCircle className='w-5'/></button></AlertDialogTrigger>
        <AlertDialogContent className='pt-4'>
          <div className='flex items-center justify-between'>
            <i className='w-10'></i>
            <h3 className='text-xl font-semibold'>{t("prono.o_help.title")}</h3>
  
            <AlertDialogCancel asChild>
              <button className='w-10 h-10 !p-0 border-none rounded-full translate-x-3'><X className='w-5'/></button>
            </AlertDialogCancel>
          </div>
  
          <div className='flex flex-col gap-4'>
            <AlertDialogCancel asChild className='justify-start !p-4 h-fit text-md'>
            <button onClick={()=>{onStartTutorial()}} className='flex items-center gap-2 duration-200 rounded-md bg-neutral-200 group hover:bg-neutral-300'>
              <span><View className='duration-200 text-neutral-500 group-hover:text-tifo' strokeWidth={1.5}/></span>
              <p className='font-medium text-neutral-700 group-hover:text-tifo'>{t("prono.o_help.q_prono")}</p>
            </button>
            </AlertDialogCancel>
            <Link to="/wedstrijdreglement" target="_blank" rel="noopener noreferrer">
            <button className='flex items-center w-full gap-2 p-4 duration-200 rounded-md bg-neutral-200 group hover:bg-neutral-300'>
              <span><Award className='duration-200 text-neutral-500 group-hover:text-tifo' strokeWidth={1.5}/></span>
              <p className='font-medium text-neutral-700 group-hover:text-tifo'>{t("prono.o_help.q_counting")}</p>
            </button>
            </Link>
            <Link to="/wedstrijdreglement" target="_blank" rel="noopener noreferrer">
            <button className='flex items-center w-full gap-2 p-4 duration-200 rounded-md bg-neutral-200 group hover:bg-neutral-300'>
              <span><Scroll className='duration-200 text-neutral-500 group-hover:text-tifo' strokeWidth={1.5}/></span>
              <p className='font-medium text-neutral-700 group-hover:text-tifo'>{t("prono.o_help.q_rules")}</p>
            </button>
            </Link>
          </div>
          
        </AlertDialogContent>
      </AlertDialog>
      </>
    )
  }

export default Game