import { useEffect, useState } from "react";

const FR = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return (
      <img
        src={`${window.location.origin}/assets/rules/${isMobile ? "puntentelling-fr-mobile.png" : "puntentelling-fr.png"}`}
        className="w-full h-auto"
        alt="Puntentelling"
      />
    );
}

export default FR