export const CYCLO_PARTNER_CONFIG:CYCLO_PARTNER_CONFIG_PROPS = {
    default: {
        prono: {
            view_ride_profile: false,
            view_rider_tabs: true
        },
        event: {
            podium_prizes: false
        },
        partner: {
            view_departments: true,
            view_partner_name: true
        },
        invite: {
            u_have_been_invited_text: true
        }
    },
    '13': {
        info: {
            name: 'tomabel'
        },
        prono: {
            view_ride_profile: true,
            view_rider_tabs: true
        },
        event: {
            podium_prizes: true
        },
        partner: {
            view_departments: false
        },
        invite: {
            url: '/uitnodiging/tomabel/160/56b835c99557428aa18981b9b6388f13',
            u_have_been_invited_text: true
        }
    },
    '60': {
        prono: {
            view_rider_tabs: false
        },
        partner: {
            view_partner_name: false,
        },
        invite: {
            u_have_been_invited_text: false
        }
    }
}

export const getCycloPartnerConfig = (partnerId:string | number) => {
    if(!partnerId){ return CYCLO_PARTNER_CONFIG.default }
    const config = CYCLO_PARTNER_CONFIG[partnerId.toString()]
    if(config) { return config }
    return CYCLO_PARTNER_CONFIG.default
}

interface CYCLO_PARTNER_CONFIG_PROPS {
    [partnerId:string]: {
        info?: {
            name?: string
        },
        prono?: {
            view_ride_profile?: boolean
            view_rider_tabs?: boolean
        },
        event?: {
            podium_prizes?: boolean
        },
        partner?: {
            view_departments?: boolean
            view_partner_name?: boolean
        },
        invite?: {
            url?: string
            u_have_been_invited_text?: boolean
        }
    }
}